export const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:10901",
  ENDPOINTS: {
    // AUTH
    LOGIN: "/auth/login",
    ROLES_ALL: "/auth/get-all-roles",
    // USERS
    USERS_CURRENT: "/auth/get-current-user",
    USERS_ALL: "/users/customer",
    USERS_GET: "/users/get",
    USERS_CREATE: "/users/create",
    USERS_UPDATE: "/users/update",
    USERS_DELETE: "/users/delete",
    USERS_CHANGE_PASSWORD: "/users/change-password",
    // CUSTOMERS
    CUSTOMERS_GET: "/customers/get",
    CUSTOMERS_SWITCH: "/customers/switch",
    CUSTOMERS_ALL: "/customers/all",
    // GROUPS
    GROUPS_ALL: "/groups/all",
    GROUPS_GET: "/groups/get",
    GROUPS_CREATE: "/groups/create",
    GROUPS_UPDATE: "/groups/update",
    GROUPS_DELETE: "/groups/delete",
    // GOALS
    GOALS_ALL: "/goals/all",
    GOALS_GET: "/goals/get",
    GOALS_CREATE: "/goals/create",
    GOALS_UPDATE: "/goals/update",
    GOALS_DELETE: "/goals/delete",
    // TASKS
    TASKS_GET: "/tasks/goal",
    TASKS_FOR_USER_GET: "/tasks/user",
    TASKS_CREATE: "/tasks/goal",
    TASKS_UPDATE: "/tasks",
    TASKS_DELETE: "/tasks",
    TASKS_TOGGLE: "/tasks",
    // METRICS
    METRICS_NEW_MEMBERS: "/metrics/new-members",
    METRICS_NEW_MEMBERS_ACTIVE: "/metrics/new-members-active",
    METRICS_NET_GROWTH_FORECAST: "/metrics/members-net-growth-created",
    METRICS_NET_GROWTH: "/metrics/members-net-growth-started",
    METRICS_CANCELLATIONS: "/metrics/cancellations",
    METRICS_INCOMING_CANCELLATIONS: "/metrics/incoming-cancellations",
    METRICS_NET_GROWTH_PERCENTAGE_FROM_START: "/metrics/members-net-growth-percentage-from-start",
    METRICS_NET_GROWTH_ABSOLUTE_FROM_START: "/metrics/members-net-growth-absolute-from-start",
    // FILTERS
    FILTERS_ALL: '/filters/all',
    FILTERS_CREATE: '/filters/create',
    FILTERS_UPDATE: '/filters/update',
    FILTERS_DELETE: '/filters/delete',
    // SELF TRACKING
    TRACKING_GET_ENTRIES: '/tracking/goal',
    TRACKING_GET_ENTRY: '/tracking/entry',
    TRACKING_TOTAL: '/tracking/total',
    TRACKING_CREATE: '/tracking/create',
    TRACKING_UPDATE: '/tracking/update',
    TRACKING_DELETE: '/tracking/delete',
  },
};
