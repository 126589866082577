import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyState from "../components/EmptyState";
import RefreshButton from "../components/RefreshButton";
import { toastStore } from "../components/ToastContainer";
import { rootStore } from "../stores/RootStore";
import SearchBar from "../components/SearchBar";
import TaskTable from "../components/TaskTable";
import { handleError } from "../errorHandler";
import SidebarPageLayout from "../components/SidebarPageLayout";

type TaskFilter = "all" | "completed" | "open";

const UserTasksPage: React.FC = observer(() => {
  const { taskStore, userStore, authStore, goalStore } = rootStore;
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("task") || "");
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<TaskFilter>("all");

  const loadInitialData = React.useCallback(async () => {
    if (!authStore.user?.id) return;

    setIsLoading(true);
    try {
      await Promise.all([goalStore.refreshGoals(), taskStore.loadTasksForUser(authStore.user.id), userStore.refreshUsers()]);
    } catch (error: any) {
      const { type, message } = handleError(error, "Aufgaben laden");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to fetch user tasks:", error);
    } finally {
      setIsLoading(false);
    }
  }, [authStore.user?.id, goalStore, taskStore, userStore]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleRefreshTasks = () => {
    loadInitialData();
  };

  const handleToggleTask = async (taskId: string) => {
    try {
      const task = taskStore.getTasksForOwner(authStore.user?.id || "").find((t) => t.id === taskId);
      if (task) {
        await taskStore.toggleTaskCompletion(task.goalId, taskId);
      }
    } catch (error: any) {
      const { type, message } = handleError(error, "Aufgabenstatus ändern");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to toggle task:", error);
    }
  };

  const filteredTasks = useMemo(() => {
    const userTasks = taskStore.getTasksForOwner(authStore.user?.id || "");
    if (!searchTerm.trim()) return userTasks;

    const searchLower = searchTerm.toLowerCase().trim();
    return userTasks.filter((task) => {
      const goalName = goalStore.goals.find((g) => g.id === task.goalId)?.description?.toLowerCase() || "";
      return task.title.toLowerCase().includes(searchLower) || goalName.includes(searchLower);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStore, authStore.user?.id, searchTerm, goalStore.goals, taskStore.tasksByGoal]);

  const getUserName = (userId: string): string => {
    const user = userStore.users.find((u) => u.id === userId);
    return user ? `${user.firstname} ${user.lastname}` : "Unknown User";
  };

  const getGoalName = (goalId: string): string => {
    const goal = goalStore.goals.find((g) => g.id === goalId);
    return goal?.description || "UNBEKANNT";
  };

  return (
    <SidebarPageLayout 
      title="Meine Aufgaben"
      onRefresh={handleRefreshTasks}
    >
      <div className="flex gap-2 w-fit flex-shrink-0">
        <SearchBar 
          onSearch={setSearchTerm} 
          placeholder="Aufgaben oder Ziele suchen" 
          paramName="task" 
        />
      </div>
      <div className="flex-1 min-h-0">
        {isLoading ? (
          <div className="p-4 text-center">Loading...</div>
        ) : filteredTasks.length > 0 ? (
          <TaskTable 
            tasks={filteredTasks} 
            onToggleTask={handleToggleTask} 
            getUserName={getUserName} 
            getGoalName={getGoalName} 
            showAddButton={false} 
            showControls={false} 
            filter={filter} 
            onFilterChange={setFilter} 
          />
        ) : (
          <EmptyState 
            message={searchTerm ? "Keine Aufgaben gefunden!" : "Keine Aufgaben verfügbar!"} 
            onRefresh={handleRefreshTasks} 
          />
        )}
      </div>
    </SidebarPageLayout>
  );
});

export default UserTasksPage;
