import { AuthStore } from "./AuthStore";
import { CustomerStore } from "./CustomerStore";
import { GoalStore } from "./GoalStore";
import { GroupStore } from "./GroupStore";
import { TaskStore } from "./TaskStore";
import { UserStore } from "./UserStore";
import { FilterStore } from "./FilterStore";
import { SelfTrackingStore } from "./SelfTrackingStore";

export class RootStore {
  authStore: AuthStore;
  userStore: UserStore;
  customerStore: CustomerStore;
  groupStore: GroupStore;
  goalStore: GoalStore;
  taskStore: TaskStore;
  filterStore: FilterStore;
  selfTrackingStore: SelfTrackingStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.customerStore = new CustomerStore(this);
    this.groupStore = new GroupStore(this);
    this.goalStore = new GoalStore(this);
    this.taskStore = new TaskStore(this);
    this.filterStore = new FilterStore(this);
    this.selfTrackingStore = new SelfTrackingStore(this);
  }
}

export const rootStore = new RootStore();
