import React from "react";

interface MetricCardProps {
  icon: any;
  title: string;
  value: string | number;
  bgColor: "white" | "primary" | "secondary" | "accent" | "neutral" | "lightBlue" | "mediumBlue";
  textColor: "white" | "black" | "primary" | "secondary";
  iconBgColor: "white" | "primary" | "secondary" | "accent" | "neutral";
  iconColor: "white" | "black" | "primary" | "secondary";
  onClick?: () => void;
  className?: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ icon: Icon, title, value, bgColor = "white", textColor = "black", iconBgColor = "primary", iconColor = "white", onClick, className = "" }) => {
  const bgColorClasses = {
    white: "bg-white",
    primary: "bg-primary",
    secondary: "bg-secondary",
    accent: "bg-accent",
    neutral: "bg-neutral",
    lightBlue: "bg-[#78a9ff]",
    mediumBlue: "bg-[#4589ff]",
  };

  const textColorClasses = {
    white: "text-white",
    black: "text-black",
    primary: "text-primary",
    secondary: "text-secondary",
  };

  return (
    <div className={`flex flex-col p-4 gap-4 w-full rounded ${bgColorClasses[bgColor]} ${onClick ? "cursor-pointer hover:opacity-90" : ""} ${className}`} onClick={onClick}>
      <div className="flex gap-2 items-center">
        <div className={`flex items-center justify-center p-2 rounded ${bgColorClasses[iconBgColor]}`}>
          <Icon className={`size-4 ${textColorClasses[iconColor]}`} />
        </div>
        <span className={`text-md ${textColorClasses[textColor]}`}>{title}</span>
      </div>
      <span className={`text-3xl font-bold ${textColorClasses[textColor]}`}>{value}</span>
    </div>
  );
};

export default MetricCard;
