import React from "react";
import RefreshButton from "./RefreshButton";

interface SidebarPageLayoutProps {
  children: React.ReactNode;
  title: string;
  onRefresh?: () => void;
  actionButton?: React.ReactNode;
  className?: string;
}

const SidebarPageLayout: React.FC<SidebarPageLayoutProps> = ({
  children,
  title,
  onRefresh,
  actionButton,
  className = "w-7/12" // Default width, kann überschrieben werden
}) => {
  return (
    <div className="flex h-full w-full justify-center pl-8 pr-8 pb-4">
      <div className={`flex flex-col mt-4 gap-4 ${className}`}>
        {/* PAGE HEADER */}
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <span className="text-2xl font-bold">{title}</span>
            {onRefresh && <RefreshButton onClick={onRefresh} showLabel={false} />}
          </div>
          {actionButton}
        </div>
        {children}
      </div>
    </div>
  );
};

export default SidebarPageLayout; 