import { ChartBarIcon, PlusIcon, TrashIcon, BuildingOfficeIcon, CalendarIcon, FlagIcon, PuzzlePieceIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmationModal } from "../components/ConfirmationModal";
import SidebarPageLayout from "../components/SidebarPageLayout";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { rootStore } from "../stores/RootStore";
import { MetricType } from "../stores/GoalStore";

const SelfTrackingPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { goalStore, selfTrackingStore, customerStore, groupStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  // Tracking state
  const [goal, setGoal] = useState<any>(null);
  const [trackingEntries, setTrackingEntries] = useState<any[]>([]);
  const [trackingValue, setTrackingValue] = useState<number | undefined>(undefined);
  const [trackingComment, setTrackingComment] = useState<string>("");
  const [trackingDate, setTrackingDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      if (!id) {
        toastStore.add({
          type: "error",
          caption: "Fehler",
          message: "Keine Ziel-ID gefunden.",
        });
        navigate("/goals");
        return;
      }

      setIsLoading(true);
      try {
        // Load goal data
        const goalData = await goalStore.getGoal(id);
        setGoal(goalData);

        // Load tracking entries
        const entries = await selfTrackingStore.getEntriesForGoal(id);
        setTrackingEntries(entries || []);
      } catch (error) {
        const { type, message } = handleError(error, "Tracking-Daten laden");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to load tracking data:", error);
        navigate("/goals");
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id, goalStore, selfTrackingStore, navigate]);

  const handleAddTrackingEntry = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!goal || trackingValue === undefined) {
      toastStore.add({
        type: "error",
        caption: "Fehler",
        message: "Bitte geben Sie einen Wert ein.",
      });
      return;
    }

    try {
      await selfTrackingStore.createEntry({
        goalId: goal.id,
        entryDate: trackingDate.toISOString().split("T")[0],
        value: trackingValue,
        comment: trackingComment || undefined,
      });

      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Der Tracking-Eintrag wurde erfolgreich erstellt.",
      });

      // Refresh tracking entries
      const entries = await selfTrackingStore.getEntriesForGoal(goal.id);
      setTrackingEntries(entries || []);

      // Refresh goal metrics
      await goalStore.loadMetricsForGoal(goal.id, goal.metricType);

      // Clear form
      setTrackingValue(undefined);
      setTrackingComment("");
      setTrackingDate(new Date());
    } catch (error) {
      const { type, message } = handleError(error, "Tracking-Eintrag erstellen");
      toastStore.add({ type, caption: "Fehler", message });
    }
  };

  const handleDeleteTrackingEntry = async (entryId: string) => {
    if (!goal) return;

    const confirmed = await showConfirmationModal({
      title: "Tracking-Eintrag löschen",
      text: "Möchten Sie diesen Tracking-Eintrag wirklich löschen?",
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await selfTrackingStore.deleteEntry(entryId, goal.id);

        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Der Tracking-Eintrag wurde erfolgreich gelöscht.",
        });

        // Refresh tracking entries
        const entries = await selfTrackingStore.getEntriesForGoal(goal.id);
        setTrackingEntries(entries || []);

        // Refresh goal metrics
        await goalStore.loadMetricsForGoal(goal.id, goal.metricType);
      } catch (error) {
        const { type, message } = handleError(error, "Tracking-Eintrag löschen");
        toastStore.add({ type, caption: "Fehler", message });
      }
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      setTrackingDate(date);
    }
  };

  const getLocationName = (goal: any) => {
    if (goal.studioId) {
      const studio = customerStore.studios.find((s) => s.id === goal.studioId);
      return `Studio: ${studio?.name || "Unbekannt"}`;
    }
    if (goal.groupId) {
      const group = groupStore.groups.find((g) => g.id === goal.groupId);
      return `Gruppe: ${group?.groupName || "Unbekannt"}`;
    }
    return "Keine Zuordnung";
  };

  const formatDate = (date: Date | string) => {
    return DateTime.fromJSDate(new Date(date)).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (!goal) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <span>Ziel nicht gefunden</span>
      </div>
    );
  }

  return (
    <div className="flex w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-10/12 h-full">
        {/* Header */}
        <div className="space-y-2">
          <h1 className="text-2xl font-bold">Kennzahl Tracken: {goalStore.getMetricTypeLabel(goal.metricType as MetricType)}</h1>
          <div className="flex items-center gap-4 text-sm">
            {/* Metric Type Badge */}
            <div className="flex items-center gap-2 opacity-70">
              <PuzzlePieceIcon className="size-4" />
              <span>{goal.description}</span>
            </div>
            {/* Date Range */}
            <div className="flex items-center gap-2 opacity-70">
              <CalendarIcon className="size-4" />
              <span>
                {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
              </span>
            </div>

            {/* Target Value */}
            <div className="flex items-center gap-2 opacity-70">
              <FlagIcon className="size-4" />
              <span>Zielwert: {goal.targetValue}</span>
            </div>

            {/* Studio/Group Name */}
            <div className="flex items-center gap-2 opacity-70">
              <BuildingOfficeIcon className="size-4" />
              <span>{getLocationName(goal)}</span>
            </div>
          </div>
        </div>

        {/* Form to add new tracking entry */}
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleAddTrackingEntry}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Date */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Datum</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="date"
                        value={trackingDate.toISOString().split("T")[0]}
                        onChange={handleDateChange}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>

                {/* Value */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Neuer Messwert</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="number"
                        value={trackingValue?.toString() || ""}
                        onChange={(e) => setTrackingValue(parseInt(e.target.value) || undefined)}
                        className="input input-sm input-bordered w-full rounded"
                        placeholder="Wert eingeben"
                        required
                      />
                    </div>
                  </td>
                </tr>

                {/* Comment */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Kommentar</span>
                  </td>
                  <td>
                    <div>
                      <textarea
                        value={trackingComment}
                        onChange={(e) => setTrackingComment(e.target.value)}
                        className="textarea textarea-bordered w-full rounded"
                        placeholder="Kommentar eingeben (optional)"
                        rows={3}
                      />
                    </div>
                  </td>
                </tr>

                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button type="button" className="btn btn-neutral" onClick={() => navigate("/goals")}>
                        Abbrechen
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={trackingValue === undefined}>
                        Messwert speichern
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        {/* List of existing entries */}
        <div className="flex-1 min-h-0">
          <div className="bg-base-100 rounded-lg border border-neutral flex flex-col h-[calc(100vh-32rem)]">
            <div className="p-4 border-b border-neutral">
              <h3 className="text-lg font-bold">Bisherige Einträge</h3>
            </div>

            <div className="flex-1 overflow-y-auto px-4">
              {trackingEntries.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-8 text-center">
                  <ChartBarIcon className="h-8 w-8 text-neutral-500 mb-2" />
                  <p className="text-lg font-medium">Keine Einträge vorhanden</p>
                  <p className="text-sm text-neutral-500">Fügen Sie oben einen neuen Tracking-Eintrag hinzu.</p>
                </div>
              ) : (
                <div className="py-4">
                  <table className="table w-full">
                    <thead>
                      <tr className="border-b border-neutral">
                        <th className="text-left font-normal">Datum</th>
                        <th className="text-left font-normal">Wert</th>
                        <th className="text-left font-normal">Kommentar</th>
                        <th className="w-16"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...trackingEntries]
                        .sort((a, b) => new Date(b.entryDate).getTime() - new Date(a.entryDate).getTime())
                        .map((entry) => (
                          <tr key={entry.id} className="border-b border-neutral hover:bg-neutral/10">
                            <td>{DateTime.fromISO(entry.entryDate.toString()).toFormat("dd.MM.yyyy")}</td>
                            <td>{entry.value}</td>
                            <td className="max-w-xs truncate">{entry.comment || "-"}</td>
                            <td>
                              <button className="btn btn-ghost btn-sm text-error" onClick={() => handleDeleteTrackingEntry(entry.id)}>
                                <TrashIcon className="h-4 w-4" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SelfTrackingPage;
