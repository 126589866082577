import {
  ArrowTurnDownRightIcon,
  BoltIcon,
  CalendarIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  EyeIcon,
  FlagIcon,
  FunnelIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoalDTO, StudioMetricsProjection } from "../backend-types";
import Badge from "../components/Badge";
import { useConfirmationModal } from "../components/ConfirmationModal";
import TableMenu, { MenuAction } from "../components/MenuAction";
import MetricCard from "../components/MetricCard";
import ProgressBar from "../components/ProgressBar";
import SearchBar from "../components/SearchBar";
import SidebarPageLayout from "../components/SidebarPageLayout";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { MetricType } from "../stores/GoalStore";
import { rootStore } from "../stores/RootStore";

// Update GroupingType to include "filter"
type GroupingType = "kpi" | "timeframe" | "group" | "filter";

// Add new type for metric filters
type MetricFilter = "all" | "planned" | "active" | "done";

// Add new type for filter selection
type FilterSelection = {
  id: string;
  active: boolean;
};

const GoalsPage: React.FC = observer(() => {
  const { goalStore, userStore, groupStore, filterStore } = rootStore;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState("");
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  // Initialize states from URL params
  const [groupingType, setGroupingType] = useState<GroupingType>((searchParams.get("grouping") as GroupingType) || "kpi");
  const [expandedSections, setExpandedSections] = useState<string[]>(searchParams.get("expanded")?.split(",").filter(Boolean) || []);
  const [showStats, setShowStats] = useState(searchParams.get("showStats") !== "false");

  // Add new state for metric filter
  const [metricFilter, setMetricFilter] = useState<MetricFilter>((searchParams.get("metricFilter") as MetricFilter) || "all");

  // Add new state for filter selection
  const [selectedFilters, setSelectedFilters] = useState<FilterSelection[]>([]);

  // Initialize selected filters when filters are loaded
  useEffect(() => {
    if (filterStore.filters.length > 0) {
      // Get filter IDs from URL if available
      const urlFilters = searchParams.get("filters")?.split(",").filter(Boolean) || [];

      setSelectedFilters(
        filterStore.filters.map((filter) => ({
          id: filter.id,
          active: urlFilters.includes(filter.id),
        }))
      );
    }
  }, [filterStore.filters, searchParams]);

  // Update URL when states change
  const updateUrlParams = (newGrouping?: GroupingType, newExpanded?: string[], newShowStats?: boolean, newMetricFilter?: MetricFilter, newSelectedFilters?: FilterSelection[]) => {
    const params = new URLSearchParams(searchParams);

    if (newGrouping !== undefined) {
      params.set("grouping", newGrouping);
    }

    if (newExpanded !== undefined) {
      if (newExpanded.length > 0) {
        params.set("expanded", newExpanded.join(","));
      } else {
        params.delete("expanded");
      }
    }

    if (newShowStats !== undefined) {
      if (!newShowStats) {
        params.set("showStats", "false");
      } else {
        params.delete("showStats");
      }
    }

    if (newMetricFilter !== undefined) {
      if (newMetricFilter === "all") {
        params.delete("metricFilter");
      } else {
        params.set("metricFilter", newMetricFilter);
      }
    }

    if (newSelectedFilters !== undefined) {
      const activeFilters = newSelectedFilters.filter((f) => f.active).map((f) => f.id);

      if (activeFilters.length > 0) {
        params.set("filters", activeFilters.join(","));
      } else {
        params.delete("filters");
      }
    }

    setSearchParams(params);
  };

  // Update handlers
  const handleGroupingChange = (newGrouping: GroupingType) => {
    setGroupingType(newGrouping);
    setExpandedSections([]); // Reset expanded sections when grouping changes
    updateUrlParams(newGrouping, []);
  };

  const toggleSection = (sectionId: string) => {
    const newExpanded = expandedSections.includes(sectionId) ? expandedSections.filter((id) => id !== sectionId) : [...expandedSections, sectionId];

    setExpandedSections(newExpanded);
    updateUrlParams(undefined, newExpanded);
  };

  const handleStatsToggle = () => {
    const newShowStats = !showStats;
    setShowStats(newShowStats);
    updateUrlParams(undefined, undefined, newShowStats);
  };

  useEffect(() => {
    goalStore.refreshGoals();
    userStore.refreshUsers();
    groupStore.refreshGroups();
    filterStore.refreshFilters();
  }, [goalStore, userStore, groupStore, filterStore]);

  const handleRefreshGoals = () => {
    goalStore.refreshGoals();
  };

  const calculateDaysRemaining = (endDate: string) => {
    const end = DateTime.fromISO(endDate);
    const today = DateTime.now().startOf("day");
    const diffDays = end.diff(today, "days").days;
    return Math.ceil(Math.max(diffDays, 0));
  };

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const getFilteredGoals = (sortDirection: "asc" | "desc" = "asc") => {
    let goals = goalStore.goals;

    return goals.slice().sort((a, b) => {
      const dateA = new Date(a.endDate).getTime();
      const dateB = new Date(b.endDate).getTime();
      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  const getFilteredAndSearchedGoals = (sortDirection: "asc" | "desc" = "asc") => {
    // First apply the metric filter
    let filtered = getFilteredGoals(sortDirection);

    // Then apply the search term filter
    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      filtered = filtered.filter((goal) => goal.description.toLowerCase().includes(searchLower));
    }

    // Apply metric filter
    switch (metricFilter) {
      case "planned":
        filtered = filtered.filter((goal) => {
          const startDate = DateTime.fromJSDate(new Date(goal.startDate));
          return startDate > DateTime.now();
        });
        break;
      case "active":
        filtered = filtered.filter((goal) => {
          const startDate = DateTime.fromJSDate(new Date(goal.startDate));
          const endDate = DateTime.fromJSDate(new Date(goal.endDate));
          const now = DateTime.now();
          const metricsState = goalStore.getMetricsState(goal.id);
          const rawData = metricsState.rawData as StudioMetricsProjection[];

          // Calculate current value with special handling for NET_GROWTH metrics
          let current = 0;
          const metricType = goal.metricType as MetricType;

          if (rawData && rawData.length > 0) {
            if (metricType === MetricType.NET_GROWTH || metricType === MetricType.NET_GROWTH_FORECAST) {
              // For NET_GROWTH metrics, use the last day's running total
              const lastDay = rawData[rawData.length - 1];
              current = lastDay.runningTotal;
            } else if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
              // For NET_GROWTH_ABSOLUTE_FROM_START, calculate the average daily growth
              // from the dailyCount values
              const nonZeroDays = rawData.filter((m) => m.dailyCount !== 0);
              if (nonZeroDays.length > 0) {
                // Calculate the average of positive daily changes only
                const positiveChanges = nonZeroDays.filter((m) => m.dailyCount > 0);
                if (positiveChanges.length > 0) {
                  const sumPositive = positiveChanges.reduce((acc, m) => acc + m.dailyCount, 0);
                  current = sumPositive / positiveChanges.length;
                } else {
                  // If no positive changes, use average of absolute values
                  const sumAbsolute = nonZeroDays.reduce((acc, m) => acc + Math.abs(m.dailyCount), 0);
                  current = sumAbsolute / nonZeroDays.length;
                }
              } else {
                current = 0;
              }
            } else if (metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
              // For percentage metrics, divide by 100 to get actual percentage
              const lastDay = rawData[rawData.length - 1];
              current = lastDay.runningTotal / 100;
            } else {
              // For other metrics, use the maximum running total as before
              current = rawData.reduce((max, d) => (d.runningTotal > max ? d.runningTotal : max), rawData[0].runningTotal);
            }
          }

          // Calculate custom metric progress for NET_GROWTH_ABSOLUTE_FROM_START
          let metricCalc;
          if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
            metricCalc = {
              progress: Math.min(100, (current / goal.targetValue) * 100),
              currentValue: current,
              isCompleted: current >= goal.targetValue,
              remainingValue: Math.max(0, goal.targetValue - current),
            };
          } else {
            metricCalc = goalStore.calculateMetricProgress(metricType, current, goal.targetValue);
          }

          return startDate <= now && endDate >= now;
        });
        break;
      case "done":
        filtered = filtered.filter((goal) => {
          const endDate = DateTime.fromJSDate(new Date(goal.endDate));
          const now = DateTime.now();
          const metricsState = goalStore.getMetricsState(goal.id);
          const rawData = metricsState.rawData as StudioMetricsProjection[];

          // Calculate current value with special handling for NET_GROWTH metrics
          let current = 0;
          const metricType = goal.metricType as MetricType;

          if (rawData && rawData.length > 0) {
            if (metricType === MetricType.NET_GROWTH || metricType === MetricType.NET_GROWTH_FORECAST) {
              // For NET_GROWTH metrics, use the last day's running total
              const lastDay = rawData[rawData.length - 1];
              current = lastDay.runningTotal;
            } else if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
              // For NET_GROWTH_ABSOLUTE_FROM_START, calculate the average daily growth
              // from the dailyCount values
              const nonZeroDays = rawData.filter((m) => m.dailyCount !== 0);
              if (nonZeroDays.length > 0) {
                // Calculate the average of positive daily changes only
                const positiveChanges = nonZeroDays.filter((m) => m.dailyCount > 0);
                if (positiveChanges.length > 0) {
                  const sumPositive = positiveChanges.reduce((acc, m) => acc + m.dailyCount, 0);
                  current = sumPositive / positiveChanges.length;
                } else {
                  // If no positive changes, use average of absolute values
                  const sumAbsolute = nonZeroDays.reduce((acc, m) => acc + Math.abs(m.dailyCount), 0);
                  current = sumAbsolute / nonZeroDays.length;
                }
              } else {
                current = 0;
              }
            } else if (metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
              // For percentage metrics, divide by 100 to get actual percentage
              const lastDay = rawData[rawData.length - 1];
              current = lastDay.runningTotal / 100;
            } else {
              // For other metrics, use the maximum running total as before
              current = rawData.reduce((max, d) => (d.runningTotal > max ? d.runningTotal : max), rawData[0].runningTotal);
            }
          }

          // Calculate custom metric progress for NET_GROWTH_ABSOLUTE_FROM_START
          let metricCalc;
          if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
            metricCalc = {
              progress: Math.min(100, (current / goal.targetValue) * 100),
              currentValue: current,
              isCompleted: current >= goal.targetValue,
              remainingValue: Math.max(0, goal.targetValue - current),
            };
          } else {
            metricCalc = goalStore.calculateMetricProgress(metricType, current, goal.targetValue);
          }

          return endDate < now || metricCalc.isCompleted;
        });
        break;
    }

    // Then apply the selected filters
    const activeFilterIds = selectedFilters.filter((f) => f.active).map((f) => f.id);

    if (activeFilterIds.length > 0) {
      filtered = filtered.filter((goal) => goal.filters?.some((filterId) => activeFilterIds.includes(filterId)));
    }

    return filtered;
  };

  const getMenuActions = (goal: GoalDTO): MenuAction[] => {
    const actions: MenuAction[] = [
      {
        label: "Bearbeiten",
        icon: <PencilIcon className="size-4" />,
        onClick: () => navigate(`/goals/edit/${goal.id}`),
      },
      {
        label: "Löschen",
        icon: <TrashIcon className="size-4" />,
        onClick: () => handleDeleteGoal(goal),
        variant: "error",
      },
    ];

    // Add 'Wert eingeben' option for SELF_TRACKING goals
    if (goal.metricType.toString() === MetricType.SELF_TRACKING.toString()) {
      actions.splice(1, 0, {
        label: "Wert eingeben",
        icon: <ChartBarIcon className="size-4" />,
        onClick: () => navigate(`/goals/tracking/${goal.id}`),
      });
    }

    return actions;
  };

  const handleDeleteGoal = async (goal: GoalDTO) => {
    const confirmed = await showConfirmationModal({
      title: "Ziel löschen",
      text: `Möchten Sie das Ziel "${goal.description}" wirklich löschen?`,
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await goalStore.deleteGoal(goal.id);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Ziel wurde erfolgreich gelöscht",
        });
      } catch (error: any) {
        const { type, message } = handleError(error, "Ziel löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete goal:", error);
      }
    }
  };

  // Add a function to render filter badges
  const renderFilterBadges = (goal: GoalDTO) => {
    if (!goal.filters || goal.filters.length === 0) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-1 mt-1">
        {goal.filters.map((filterId) => {
          const filter = filterStore.filters.find((f) => f.id === filterId);
          if (!filter) return null;

          return <Badge key={filterId} text={filter.name} icon={<FunnelIcon className="size-3" />} className="bg-neutral/10 text-xs" />;
        })}
      </div>
    );
  };

  const renderGoalRow = (goal: GoalDTO) => {
    // Pre-calculate metrics state outside the render sections
    const metricsState = goalStore.getMetricsState(goal.id);
    const rawData = metricsState.rawData as StudioMetricsProjection[];

    // Special handling for NET_GROWTH metrics
    let current = 0;
    const metricType = goal.metricType as MetricType;

    if (rawData && rawData.length > 0) {
      if (metricType === MetricType.NET_GROWTH || metricType === MetricType.NET_GROWTH_FORECAST) {
        // For NET_GROWTH metrics, use the last day's running total
        // This ensures we get the correct net growth value that accounts for cancellations
        const lastDay = rawData[rawData.length - 1];
        current = lastDay.runningTotal;
      } else if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
        // For NET_GROWTH_ABSOLUTE_FROM_START, calculate the average daily growth
        // from the dailyCount values
        const nonZeroDays = rawData.filter((m) => m.dailyCount !== 0);
        if (nonZeroDays.length > 0) {
          // Calculate the average of positive daily changes only
          const positiveChanges = nonZeroDays.filter((m) => m.dailyCount > 0);
          if (positiveChanges.length > 0) {
            const sumPositive = positiveChanges.reduce((acc, m) => acc + m.dailyCount, 0);
            current = sumPositive / positiveChanges.length;
          } else {
            // If no positive changes, use average of absolute values
            const sumAbsolute = nonZeroDays.reduce((acc, m) => acc + Math.abs(m.dailyCount), 0);
            current = sumAbsolute / nonZeroDays.length;
          }
        } else {
          current = 0;
        }
      } else if (metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
        // For percentage metrics, divide by 100 to get actual percentage
        const lastDay = rawData[rawData.length - 1];
        current = lastDay.runningTotal / 100;
      } else {
        // For other metrics, use the maximum running total as before
        current = rawData.reduce((max, d) => (d.runningTotal > max ? d.runningTotal : max), rawData[0].runningTotal);
      }
    }

    const metricCalc = goalStore.calculateMetricProgress(metricType, current, goal.targetValue);

    const startDate = DateTime.fromJSDate(new Date(goal.startDate || rawData?.[0]?.date || new Date()));
    const endDate = DateTime.fromJSDate(new Date(goal.endDate));
    const now = DateTime.now();

    const totalDays = Math.max(endDate.diff(startDate, "days").days, 1);
    const elapsedDays = Math.max(Math.min(now.diff(startDate, "days").days, totalDays), 0);
    const timeProgress = (elapsedDays / totalDays) * 100;

    // Calculate custom progress for NET_GROWTH_ABSOLUTE_FROM_START
    let goalProgress = metricCalc.progress;
    if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
      goalProgress = Math.min(100, (current / goal.targetValue) * 100);
    }

    const getStatusBadge = () => {
      const daysRemaining = calculateDaysRemaining(goal.endDate.toString());

      if (daysRemaining > 0) {
        return <Badge text={`Noch ${daysRemaining} Tag(e)`} className="border-[#333333] w-full" />;
      }

      // Goal is completed (deadline reached)
      if (metricCalc.isCompleted) {
        return <Badge text="Ziel erreicht" className="border-success bg-success/10 text-success/80 rounded-sm w-full" icon={<CheckCircleIcon className="size-5" />} />;
      } else {
        return <Badge text="Ziel verfehlt" className="border-error/90 bg-error/10 text-error/80 rounded-sm w-full" icon={<XCircleIcon className="size-5" />} />;
      }
    };

    return (
      <tr key={goal.id} className="border-b border-[#333333] last:border-b-0 hover:bg-base-200/50 bg-neutral">
        {/* DESC*/}
        <td>
          <div className="flex items-center gap-2">
            <ArrowTurnDownRightIcon className="size-8 text-[#444444] -mt-3" />
            <div className="flex-grow min-w-0 cursor-pointer" onClick={() => navigate("/goals/details/" + goal.id)}>
              <div className="text-sm truncate" title={goal.description || ""}>
                {goal.description || "KEINE BESCHREIBUNG"}
              </div>
              {renderFilterBadges(goal)}
            </div>
          </div>
        </td>
        {/* STATUS */}
        <td>
          <div className="flex gap-2">{getStatusBadge()}</div>
        </td>
        {/* KENNZAHL BADGE */}
        <td>
          <div className="flex gap-2">
            <Badge text={goalStore.getMetricTypeLabel(goal.metricType as MetricType)} className="border-[#333333]" />
          </div>
        </td>
        {/* Fortschritt */}
        <td>
          {!goal.groupId && !goal.studioId ? (
            <div className="text-red-400">Zuordnung fehlt/gelöscht</div>
          ) : metricsState.isLoading ? (
            <div className="flex items-center justify-center h-6">
              <div className="loading loading-spinner loading-sm"></div>
            </div>
          ) : metricsState.error ? (
            <div className="text-error font-medium">ERROR</div>
          ) : (
            <div className="flex gap-2">
              <ProgressBar current={current} goal={goal.targetValue} timeProgress={timeProgress} goalProgress={goalProgress} metricType={metricType} />
            </div>
          )}
        </td>
        {/* Deadline */}
        <td>
          <div className="flex items-center">
            {calculateDaysRemaining(goal.endDate.toString()) === 0 ? (
              <span className="text-sm">Abgelaufen</span>
            ) : (
              <span className="text-sm">{formatDate(goal.endDate.toString())}</span>
            )}
          </div>
        </td>
        {/* Owner */}
        <td>
          <div className="flex items-center gap-2">
            <UserIcon className="size-4" />
            <span className="text-sm">{goal.ownerId ? userStore.getUserName(goal.ownerId) : "Nicht zugewiesen"}</span>
          </div>
        </td>
        {/* CONTROLLS */}
        <td>
          <TableMenu actions={getMenuActions(goal)} />
        </td>
      </tr>
    );
  };

  // Render the goals table for a section
  const renderGoalsTable = (goals: GoalDTO[]) => (
    <div className="overflow-x-auto">
      <table className="table w-full table-fixed min-w-[80rem]">
        <colgroup>
          <col className="w-[24rem]" /> {/* Name */}
          <col className="w-[10rem]" /> {/* Status */}
          <col className="w-[10rem]" /> {/* Kennzahl - etwas schmaler */}
          <col className="w-[20rem]" /> {/* Fortschritt - etwas breiter */}
          <col className="w-[7rem]" /> {/* Deadline */}
          <col className="w-[8rem]" /> {/* Eigentümer */}
          <col className="w-[3rem]" /> {/* Controls */}
        </colgroup>
        <thead className="sticky top-0 bg-base-100 z-10">
          <tr className="border-b border-neutral">
            <th className="text-left font-normal">Name</th>
            <th className="text-left font-normal">Status</th>
            <th className="text-left font-normal">Kennzahl</th>
            <th className="text-left font-normal">Fortschritt</th>
            <th className="text-left font-normal">Deadline</th>
            <th className="text-left font-normal">Eigentümer</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{goals.map(renderGoalRow)}</tbody>
      </table>
    </div>
  );

  // Add this helper function to count unique studios
  const countUniqueStudios = (goals: GoalDTO[]): number => {
    const uniqueStudios = new Set(goals.map((goal) => goal.studioId));
    return uniqueStudios.size;
  };

  // Add this helper function near the other helper functions
  const createSectionBadges = (goals: GoalDTO[]) => [{ text: `${countUniqueStudios(goals)} Studio(s)` }, { text: `${goals.length} Ziel(e)` }];

  // Neue Helper-Funktion für den einheitlichen Header-Style
  const renderCollapsibleHeader = (id: string, label: string, badges: { text: string }[], isExpanded: boolean) => (
    <div className="rounded">
      <div className="flex items-center py-2 cursor-pointer hover:bg-neutral/10 gap-2" onClick={() => toggleSection(id)}>
        <div className="flex items-center justify-center size-6 bg-[#333333] rounded">
          <ChevronRightIcon className={`size-4 transition-transform duration-200 ${isExpanded ? "rotate-90" : ""}`} />
        </div>
        <span className="font-bold">{label}</span>
        <div className="flex gap-2 ml-4">
          {badges.map((badge, index) => (
            <Badge key={index} text={badge.text} className="border-[#333333]" />
          ))}
        </div>
      </div>
    </div>
  );

  // Add handler for metric card clicks
  const handleMetricCardClick = (filter: MetricFilter) => {
    const newFilter = metricFilter === filter ? "all" : filter;
    setMetricFilter(newFilter);
    updateUrlParams(undefined, undefined, undefined, newFilter);
  };

  // Add two different empty state renderers
  const renderMainEmptyState = () => (
    <div className="flex justify-center items-center p-8 text-white">
      <span>Keine Daten vorhanden</span>
    </div>
  );

  const renderCollapsibleEmptyState = () => (
    <div className="py-4 text-white">
      <span>Keine Daten vorhanden</span>
    </div>
  );

  // Update renderSections to handle the "filter" grouping type
  const renderSections = () => {
    const filteredGoals = getFilteredAndSearchedGoals();

    // Show centered empty state if no goals match the current filter
    if (filteredGoals.length === 0) {
      return renderMainEmptyState();
    }

    switch (groupingType) {
      case "kpi": {
        const sections = Object.values(MetricType)
          .map((type) => {
            const goalsForType = filteredGoals.filter((goal) => goal.metricType === type);
            if (goalsForType.length === 0) return null;

            return (
              <div key={type}>
                {renderCollapsibleHeader(type, goalStore.getMetricTypeLabel(type), createSectionBadges(goalsForType), expandedSections.includes(type))}
                <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes(type) ? "max-h-[500px]" : "max-h-0"}`}>
                  <div className="overflow-y-auto max-h-[500px]">
                    <div className="px-4">{renderGoalsTable(goalsForType)}</div>
                  </div>
                </div>
              </div>
            );
          })
          .filter(Boolean);

        // Show centered empty state if no sections have goals
        return sections.length > 0 ? sections : renderMainEmptyState();
      }

      case "timeframe": {
        const sections = [
          { id: "completed", label: "Abgeschlossen" },
          { id: "veryShort", label: "Sehr kurzfristig (1 Monat)" },
          { id: "short", label: "Kurzfristig (1-3 Monate)" },
          { id: "medium", label: "Mittelfristig (4-12 Monate)" },
          { id: "long", label: "Langfristig (>1 Jahr)" },
        ]
          .map(({ id, label }) => {
            const goalsForTimeframe = filteredGoals.filter((goal) => {
              const endDate = DateTime.fromJSDate(new Date(goal.endDate));
              const now = DateTime.now().startOf("day");
              const remainingDays = endDate.diff(now, "days").days;

              switch (id) {
                case "completed":
                  return endDate < now;
                case "veryShort":
                  return remainingDays >= 0 && remainingDays <= 30;
                case "short":
                  return remainingDays > 30 && remainingDays <= 90;
                case "medium":
                  return remainingDays > 90 && remainingDays <= 365;
                case "long":
                  return remainingDays > 365;
                default:
                  return false;
              }
            });

            if (goalsForTimeframe.length === 0) return null;

            return (
              <div key={id}>
                {renderCollapsibleHeader(id, label, createSectionBadges(goalsForTimeframe), expandedSections.includes(id))}
                <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes(id) ? "max-h-[500px]" : "max-h-0"}`}>
                  <div className="overflow-y-auto max-h-[500px]">
                    <div className="px-4">{renderGoalsTable(goalsForTimeframe)}</div>
                  </div>
                </div>
              </div>
            );
          })
          .filter(Boolean);

        // Show centered empty state if no sections have goals
        return sections.length > 0 ? sections : renderMainEmptyState();
      }

      case "group": {
        const ungroupedGoals = filteredGoals.filter((goal) => !goal.groupId);
        const sections = [
          <div key="ungrouped">
            {renderCollapsibleHeader("ungrouped", "Ohne Gruppe", createSectionBadges(ungroupedGoals), expandedSections.includes("ungrouped"))}
            <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes("ungrouped") ? "max-h-[500px]" : "max-h-0"}`}>
              <div className="overflow-y-auto max-h-[500px]">
                <div className="px-4">{ungroupedGoals.length > 0 ? renderGoalsTable(ungroupedGoals) : renderCollapsibleEmptyState()}</div>
              </div>
            </div>
          </div>,
          ...groupStore.groups.map((group) => {
            const goalsForGroup = filteredGoals.filter((goal) => goal.groupId === group.id);

            return (
              <div key={group.id}>
                {renderCollapsibleHeader(group.id, group.groupName, createSectionBadges(goalsForGroup), expandedSections.includes(group.id))}
                <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes(group.id) ? "max-h-[500px]" : "max-h-0"}`}>
                  <div className="overflow-y-auto max-h-[500px]">
                    <div className="px-4">{goalsForGroup.length > 0 ? renderGoalsTable(goalsForGroup) : renderCollapsibleEmptyState()}</div>
                  </div>
                </div>
              </div>
            );
          }),
        ].filter(Boolean);

        // Show centered empty state if no sections have goals
        return sections.length > 0 ? sections : renderMainEmptyState();
      }

      case "filter": {
        // Get all filters from the filterStore
        const allFilters = filterStore.filters;

        // Create a section for goals without any filters
        const goalsWithoutFilters = filteredGoals.filter((goal) => !goal.filters || goal.filters.length === 0);

        // Create sections for each filter
        const sections = allFilters
          .map((filter) => {
            const goalsWithFilter = filteredGoals.filter((goal) => goal.filters && goal.filters.includes(filter.id));

            if (goalsWithFilter.length === 0) return null;

            return (
              <div key={`filter-${filter.id}`}>
                {renderCollapsibleHeader(`filter-${filter.id}`, filter.name, createSectionBadges(goalsWithFilter), expandedSections.includes(`filter-${filter.id}`))}
                <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes(`filter-${filter.id}`) ? "max-h-[500px]" : "max-h-0"}`}>
                  <div className="overflow-y-auto max-h-[500px]">
                    <div className="px-4">{renderGoalsTable(goalsWithFilter)}</div>
                  </div>
                </div>
              </div>
            );
          })
          .filter(Boolean);

        // Add section for goals without filters if there are any
        if (goalsWithoutFilters.length > 0) {
          sections.push(
            <div key="filter-none">
              {renderCollapsibleHeader("filter-none", "Keine Filter", createSectionBadges(goalsWithoutFilters), expandedSections.includes("filter-none"))}
              <div className={`overflow-hidden transition-all duration-250 ease-in-out ${expandedSections.includes("filter-none") ? "max-h-[500px]" : "max-h-0"}`}>
                <div className="overflow-y-auto max-h-[500px]">
                  <div className="px-4">{renderGoalsTable(goalsWithoutFilters)}</div>
                </div>
              </div>
            </div>
          );
        }

        return sections.length > 0 ? sections : renderMainEmptyState();
      }

      default:
        return renderMainEmptyState();
    }
  };

  return (
    <SidebarPageLayout
      title="Ziele"
      onRefresh={handleRefreshGoals}
      actionButton={
        <button className="btn btn-primary btn-s" onClick={() => navigate("/goals/create")}>
          <p>Ziel festlegen</p>
        </button>
      }
      className="w-full" // Goals braucht volle Breite
    >
      {/* Needed for confirmation popups */}
      <ConfirmationModalComponent />
      {/* Stats collapsible section */}
      <div>
        <div className="flex items-center cursor-pointer hover:bg-neutral/10 py-1" onClick={handleStatsToggle}>
          <span className="text-xs text-[#666666]">Informationen</span>
          <ChevronRightIcon className={`size-3 transition-transform duration-200 ml-1 text-[#666666] ${showStats ? "rotate-90" : ""}`} />
        </div>

        <div className={`overflow-hidden transition-all duration-250 ease-in-out ${showStats ? "max-h-[500px] py-4" : "max-h-0"}`}>
          <div className="flex w-full justify-between gap-8">
            <MetricCard
              key={"allGoals"}
              icon={EyeIcon}
              title="Alle Ziele"
              value={goalStore.goals.length}
              textColor={"black"}
              bgColor="white"
              iconBgColor="primary"
              iconColor="white"
              onClick={() => handleMetricCardClick("all")}
              className={`${metricFilter === "all" ? "border-2 border-white" : ""}`}
            />
            <MetricCard
              key={"plannedGoals"}
              icon={CalendarIcon}
              title="Geplant"
              value={goalStore.plannedGoals?.length || 0}
              textColor="white"
              bgColor="lightBlue"
              iconBgColor="white"
              iconColor="primary"
              onClick={() => handleMetricCardClick("planned")}
              className={`${metricFilter === "planned" ? "border-2 border-white" : ""}`}
            />
            <MetricCard
              key={"activeGoals"}
              icon={BoltIcon}
              title="Aktiv"
              value={goalStore.activeGoals?.length || 0}
              textColor="white"
              bgColor="mediumBlue"
              iconBgColor="white"
              iconColor="primary"
              onClick={() => handleMetricCardClick("active")}
              className={`${metricFilter === "active" ? "border-2 border-white" : ""}`}
            />
            <MetricCard
              key={"doneGoals"}
              icon={FlagIcon}
              title="Fertig"
              value={goalStore.completedGoals?.length || 0}
              textColor="white"
              bgColor="primary"
              iconBgColor="white"
              iconColor="primary"
              onClick={() => handleMetricCardClick("done")}
              className={`${metricFilter === "done" ? "border-2 border-white" : ""}`}
            />
          </div>
        </div>
      </div>

      {/* FILTER SECTION */}
      <div className="flex justify-end items-center w-full gap-4">
        <div className="w-64">
          <SearchBar onSearch={setSearchTerm} placeholder="Ziele suchen" paramName="goal" />
        </div>

        <select className="select select-bordered select-sm w-48" value={groupingType} onChange={(e) => handleGroupingChange(e.target.value as GroupingType)}>
          <option value="kpi">Nach Kennzahl</option>
          <option value="timeframe">Nach Zeitraum</option>
          <option value="group">Nach Gruppe</option>
          <option value="filter">Nach Filter</option>
        </select>
      </div>

      {/* Filter Selection */}
      {/* {renderFilterSelection()} */}

      {/* Collapsible sections */}
      <div className="flex flex-col gap-2">{renderSections()}</div>
    </SidebarPageLayout>
  );
});

export default GoalsPage;
