import React, { useState, useRef, useEffect } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export interface MenuAction {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  variant?: "default" | "error";
}

interface TableMenuProps {
  actions: MenuAction[];
}

const TableMenu: React.FC<TableMenuProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const getDropdownPosition = () => {
    if (!buttonRef.current) return {};
    
    const rect = buttonRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    
    // Estimate menu height based on number of actions (each action is roughly 40px)
    const estimatedMenuHeight = actions.length * 40 + 16; // 16px for padding
    const menuWidth = 200; // min-w-[200px]
    
    // Check if menu would extend beyond viewport bottom
    const wouldExtendBeyondBottom = rect.bottom + estimatedMenuHeight > viewportHeight;
    
    // Check if menu would extend beyond viewport right
    const wouldExtendBeyondRight = rect.left + menuWidth > viewportWidth;
    
    // Adjust left position if needed
    const leftPosition = wouldExtendBeyondRight 
      ? `${viewportWidth - menuWidth - 16}px` // 16px padding from edge
      : `${rect.left}px`;
    
    if (wouldExtendBeyondBottom) {
      // Position above the button
      return {
        bottom: `${viewportHeight - rect.top}px`,
        left: leftPosition,
        marginTop: '0',
        marginBottom: '8px', // Add margin at the bottom when opening upward
      };
    } else {
      // Position below the button (original behavior)
      return {
        top: `${rect.bottom}px`,
        left: leftPosition,
        marginTop: '8px', // Add margin at the top when opening downward
        marginBottom: '0',
      };
    }
  };

  return (
    <div ref={menuRef} className="relative inline-block">
      <button ref={buttonRef} className="btn btn-ghost btn-xs" onClick={() => setIsOpen(!isOpen)} title="Actions">
        <EllipsisVerticalIcon className="size-4" />
      </button>

      {isOpen && (
        <div 
          ref={dropdownRef}
          className="fixed z-50 min-w-[200px] bg-base-100 border border-neutral rounded p-2 shadow-lg" 
          style={getDropdownPosition()}
        >
          {actions.map((action, index) => (
            <button
              key={index}
              onClick={() => {
                action.onClick();
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-neutral flex rounded items-center gap-2 ${
                action.variant === "error" ? "text-error" : ""
              }`}
            >
              {action.icon}
              <span>{action.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableMenu;
