import { BuildingOfficeIcon, CalendarIcon, FlagIcon, PuzzlePieceIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import TaskTable from "../components/TaskTable";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { MetricType } from "../stores/GoalStore";
import { rootStore } from "../stores/RootStore";
import { StudioMetricsProjection } from "../backend-types";

const GoalDetailsPage = observer(() => {
  const { id } = useParams();
  const { goalStore, taskStore, userStore, authStore, customerStore, groupStore, filterStore, selfTrackingStore } = rootStore;
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [selfTrackingEntries, setSelfTrackingEntries] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      userStore.refreshUsers();
      goalStore.refreshGoals();
      taskStore.loadTasksForGoal(id);
      filterStore.refreshFilters();

      // Für Self-Tracking Ziele auch die individuellen Tracking-Einträge laden
      const loadSelfTrackingData = async () => {
        const goal = goalStore.goals.find((g) => g.id === id);
        if (goal && goal.metricType === MetricType.SELF_TRACKING) {
          try {
            const entries = await selfTrackingStore.getEntriesForGoal(id);
            setSelfTrackingEntries(entries || []);
          } catch (error) {
            console.error("Failed to load self-tracking entries:", error);
          }
        }
      };

      loadSelfTrackingData();
    }
  }, [id, goalStore, taskStore, userStore, filterStore, selfTrackingStore]);

  useEffect(() => {
    if (isAddingTask && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTask]);

  const handleAddTask = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newTaskTitle.trim() && id) {
      try {
        await taskStore.createTask(id, newTaskTitle, authStore.user!.id || "");
        setNewTaskTitle("");
        setIsAddingTask(false);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe erstellen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to create task:", error);
      }
    } else if (e.key === "Escape") {
      setNewTaskTitle("");
      setIsAddingTask(false);
    }
  };

  const handleBlur = () => {
    if (!newTaskTitle.trim()) {
      setIsAddingTask(false);
    }
  };

  const handleToggleTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.toggleTaskCompletion(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabenstatus ändern");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to toggle task:", error);
      }
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.deleteTask(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete task:", error);
      }
    }
  };

  const getLocationName = (goal: any) => {
    if (goal.studioId) {
      const studio = customerStore.studios.find((s) => s.id === goal.studioId);
      return `Studio: ${studio?.name || "Unbekannt"}`;
    }
    if (goal.groupId) {
      const group = groupStore.groups.find((g) => g.id === goal.groupId);
      return `Gruppe: ${group?.groupName || "Unbekannt"}`;
    }
    return "Keine Zuordnung";
  };

  const goal = goalStore.goals.find((g) => g.id === id);
  const metricsState = goal ? goalStore.getMetricsState(goal.id) : null;
  const tasks = id ? taskStore.getTasksForGoal(id) : [];
  const isLoadingTasks = id ? taskStore.isLoadingForGoal(id) : false;

  if (!goal || !metricsState || metricsState.isLoading || isLoadingTasks) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <div className="loading loading-spinner loading-lg"></div>
      </div>
    );
  }

  const metrics = metricsState.rawData || [];

  // Function to transform metric data for chart display
  const getChartData = () => {
    if (!metrics || metrics.length === 0) return [];

    // Für Self-Tracking-Ziele verwende die individuellen Tracking-Einträge
    if (goal.metricType === MetricType.SELF_TRACKING && selfTrackingEntries.length > 0) {
      // Sortiere die Einträge nach Datum
      const sortedEntries = [...selfTrackingEntries].sort((a, b) => new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime());

      // Convertiere die Tracking-Einträge ins gewünschte Format
      let runningTotal = 0;
      return sortedEntries.map((entry) => {
        const value = entry.value;
        runningTotal += value;
        return {
          date: new Date(entry.entryDate),
          dailyCount: value,
          runningTotal: runningTotal,
        };
      });
    }

    // For percentage metrics, the values come from the backend already multiplied by 100
    // to preserve 2 decimal places as integers (e.g., 1.72% is stored as 172)
    // We need to divide by 100 to get the actual percentage values for the chart
    if (goal.metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
      const chartData = (metrics as StudioMetricsProjection[]).map((metric) => ({
        ...metric,
        dailyCount: metric.dailyCount / 100,
        runningTotal: metric.runningTotal / 100,
      }));
      return chartData;
    }

    // For other metrics, return as is
    return metrics;
  };

  // Special handling for NET_GROWTH metrics and Self-Tracking
  let currentValue = 0;
  let adjustedTargetValue = goal.targetValue;
  const metricType = goal.metricType as MetricType;

  if (metricType === MetricType.SELF_TRACKING && selfTrackingEntries.length > 0) {
    // Für Self-Tracking-Ziele summiere einfach alle Tracking-Einträge
    currentValue = selfTrackingEntries.reduce((sum, entry) => sum + entry.value, 0);
  } else if (metrics && metrics.length > 0) {
    if (metricType === MetricType.NET_GROWTH || metricType === MetricType.NET_GROWTH_FORECAST) {
      // For NET_GROWTH metrics, use the last day's running total
      // This ensures we get the correct net growth value that accounts for cancellations
      const lastDay = metrics[metrics.length - 1];
      currentValue = lastDay.runningTotal;
    } else if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
      // For NET_GROWTH_ABSOLUTE_FROM_START, calculate the average daily growth
      // from the dailyCount values

      // Get all non-zero days
      const nonZeroDays = (metrics as StudioMetricsProjection[]).filter((m) => m.dailyCount !== 0);

      if (nonZeroDays.length > 0) {
        // Calculate the average of absolute values of daily changes
        const sumAbsolute = nonZeroDays.reduce((acc, m) => {
          const absValue = Math.abs(m.dailyCount);
          return acc + absValue;
        }, 0);

        // Calculate the average of positive daily changes only
        const positiveChanges = nonZeroDays.filter((m) => m.dailyCount > 0);
        const sumPositive = positiveChanges.reduce((acc, m) => {
          return acc + m.dailyCount;
        }, 0);

        // Use the average of positive changes if available, otherwise use average of absolute values
        if (positiveChanges.length > 0) {
          currentValue = sumPositive / positiveChanges.length;
        } else {
          currentValue = sumAbsolute / nonZeroDays.length;
        }
      } else {
        currentValue = 0;
      }
    } else if (metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
      // For percentage metrics, the values come from the backend already multiplied by 100
      // to preserve 2 decimal places as integers (e.g., 1.72% is stored as 172)
      const lastDay = metrics[metrics.length - 1];
      currentValue = lastDay.runningTotal;
      // For percentage metrics, we need to adjust the target value to match the scale
      adjustedTargetValue = goal.targetValue * 100;
    } else {
      // For other metrics, use the maximum running total as before
      currentValue = Math.max(...(metrics as StudioMetricsProjection[]).map((d) => d.runningTotal));
    }
  }

  const startDate = goal.startDate ? DateTime.fromJSDate(new Date(goal.startDate)) : DateTime.fromJSDate((metrics as StudioMetricsProjection[])[0]?.date || new Date());
  const endDate = DateTime.fromJSDate(new Date(goal.endDate));
  const now = DateTime.now();

  const totalDays = Math.max(endDate.diff(startDate, "days").days, 1);
  const elapsedDays = Math.max(Math.min(now.diff(startDate, "days").days, totalDays), 0);

  // Calculate metric progress
  let metricCalc;
  if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
    // For NET_GROWTH_ABSOLUTE_FROM_START, compare the current average daily growth
    // with the target average daily growth
    const progress = Math.min(100, (currentValue / adjustedTargetValue) * 100);
    const isCompleted = currentValue >= adjustedTargetValue;
    const remainingValue = Math.max(0, adjustedTargetValue - currentValue);

    metricCalc = {
      progress,
      currentValue,
      isCompleted,
      remainingValue,
    };
  } else {
    metricCalc = goalStore.calculateMetricProgress(metricType, currentValue, adjustedTargetValue);
  }

  // Calculate daily metrics
  let dailyMetrics;
  if (metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START) {
    // For NET_GROWTH_ABSOLUTE_FROM_START, we need custom calculations
    const isDeadlineReached = elapsedDays >= totalDays;

    // Calculate the required daily average to reach the target
    let requiredDaily;
    if (isDeadlineReached || metricCalc.isCompleted) {
      requiredDaily = 0;
    } else {
      // Calculate how much more we need to achieve per day to reach the target
      const remainingDays = totalDays - elapsedDays;

      if (remainingDays > 0) {
        // If we're below target, calculate what we need to achieve daily to reach the target
        if (currentValue < adjustedTargetValue) {
          // Calculate how much more growth is needed in total to reach the target
          const totalGrowthNeeded = adjustedTargetValue * totalDays;

          // Calculate how much growth we've already achieved
          // For this, we need to know how many days had positive growth
          const positiveChanges = (metrics as StudioMetricsProjection[]).filter((m) => m.dailyCount > 0);
          const totalPositiveGrowth = positiveChanges.reduce((acc, m) => acc + m.dailyCount, 0);

          // Calculate how much more growth is needed
          const remainingGrowthNeeded = totalGrowthNeeded - totalPositiveGrowth;

          // Calculate the required daily average for the remaining days
          requiredDaily = Math.max(0, remainingGrowthNeeded / remainingDays);
        } else {
          // We've already reached or exceeded the target
          requiredDaily = 0;
        }
      } else {
        requiredDaily = 0;
      }
    }

    // Project the final value based on the current average
    const projectedValue = currentValue;
    const timeProgress = (elapsedDays / totalDays) * 100;

    dailyMetrics = {
      currentDaily: currentValue,
      requiredDaily: requiredDaily,
      projectedValue: projectedValue,
      timeProgress: timeProgress,
      isDeadlineReached: isDeadlineReached,
    };
  } else {
    dailyMetrics = goalStore.calculateDailyMetrics(metricType, metrics as StudioMetricsProjection[], currentValue, adjustedTargetValue, elapsedDays, totalDays);
  }

  const formatDate = (date: Date | string) => {
    return DateTime.fromJSDate(new Date(date)).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const renderFilterBadges = () => {
    if (!goal || !goal.filters || goal.filters.length === 0) {
      return null;
    }

    return (
      <div className="flex items-center gap-2 opacity-70">
        <FunnelIcon className="size-4" />
        <div className="flex flex-wrap gap-1">
          {goal.filters
            .map((filterId) => {
              const filter = filterStore.filters.find((f) => f.id === filterId);
              return filter ? filter.name : null;
            })
            .filter(Boolean)
            .join(", ")}
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col mt-4 gap-4 w-10/12 h-full">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold">{goal.description}</h1>
            <div className="flex items-center gap-4 text-sm">
              {/* Kennzahl Badge */}
              <div className="flex items-center gap-2 opacity-70">
                <PuzzlePieceIcon className="size-4" />
                <span>{goalStore.getMetricTypeLabel(goal.metricType as MetricType)}</span>
              </div>
              {/* Date Range */}
              <div className="flex items-center gap-2 opacity-70">
                <CalendarIcon className="size-4" />
                <span>
                  {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
                </span>
              </div>

              {/* Target Value */}
              <div className="flex items-center gap-2 opacity-70">
                <FlagIcon className="size-4" />
                <span>
                  Zielwert:&nbsp;
                  {metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START ? `${goal.targetValue}%` : goal.targetValue.toLocaleString()}
                </span>
              </div>

              {/* Studio/Group Name */}
              <div className="flex items-center gap-2 opacity-70">
                <BuildingOfficeIcon className="size-4" />
                <span>{getLocationName(goal)}</span>
              </div>

              {/* Filters */}
              {renderFilterBadges()}
            </div>
          </div>
        </div>

        {/* Metrics Cards */}
        <div className="grid grid-cols-3 gap-4">
          {/* Progress Chart */}
          <div className="border border-neutral rounded p-4 col-span-2">
            <div className="font-bold mb-4">Fortschrittsverlauf</div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={getChartData()}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" opacity={0.2} />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("dd.MM.yy")}
                    tick={{ fontSize: 11 }}
                    interval={"preserveEnd"}
                    minTickGap={5}
                  />
                  <YAxis tick={{ fontSize: 11 }} domain={[0, "auto"]} />
                  <Tooltip
                    formatter={(value, name) => {
                      // Für Prozent-Metriken zeigen wir den Wert mit %-Zeichen an
                      if (metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START) {
                        // Der Wert wurde bereits in getChartData() durch 100 geteilt
                        const numValue = typeof value === "number" ? value : parseFloat(value as string);
                        return [`${numValue.toFixed(2)}%`];
                      }
                      return [value, "Wert"];
                    }}
                    labelFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("dd.MM.yyyy")}
                    contentStyle={{
                      backgroundColor: "#161616",
                      border: "1px solid #374151",
                      borderRadius: "0.375rem",
                      fontSize: "11px",
                      padding: "0.5rem",
                      color: "white",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey={
                      metricType === MetricType.NET_GROWTH || metricType === MetricType.NET_GROWTH_FORECAST || metricType === MetricType.NET_GROWTH_ABSOLUTE_FROM_START
                        ? "dailyCount"
                        : metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START
                        ? "runningTotal"
                        : "runningTotal"
                    }
                    stroke="#2563eb"
                    dot={false}
                    activeDot={{
                      fill: "#2563eb",
                      stroke: "#white",
                      r: 6,
                      strokeWidth: 2,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Progress vs Time - Reorganized */}
          <div className="border border-neutral rounded p-4">
            <div className="font-bold mb-4">Fortschritt vs. Zeit</div>
            <div className="space-y-4">
              {/* Zeitfortschritt */}
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zeitfortschritt</span>
                  <span>{dailyMetrics.timeProgress.toFixed(1)}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="bg-primary h-2 rounded-full" style={{ width: `${dailyMetrics.timeProgress}%` }} />
                </div>
              </div>

              {/* Zielfortschritt */}
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zielfortschritt</span>
                  <span>{metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START ? `${metricCalc.progress.toFixed(2)}%` : `${metricCalc.progress.toFixed(1)}%`}</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className="bg-primary h-2 rounded-full"
                    style={{
                      width: `${Math.max(0, Math.min(metricCalc.progress, 100))}%`,
                    }}
                  />
                </div>
              </div>

              <div className="pt-4 border-t border-neutral space-y-4">
                {/* Prognose */}
                <div>
                  <p className="text-sm font-medium">Prognose zum {formatDate(goal.endDate)}</p>
                  {dailyMetrics.isDeadlineReached ? (
                    <p className="text-sm opacity-70">Deadline erreicht</p>
                  ) : (
                    <p className="text-xl font-bold">
                      {metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START ? `${(dailyMetrics.projectedValue / 100).toFixed(2)}%` : dailyMetrics.projectedValue.toFixed(1)}
                    </p>
                  )}
                </div>

                {/* Differenz zum Zielwert */}
                <div>
                  <p className="text-sm font-medium">Differenz zum Zielwert</p>
                  {metricCalc.isCompleted ? (
                    <p className="text-sm opacity-70">Ziel erreicht</p>
                  ) : (
                    <p className="text-xl font-bold">
                      {metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START
                        ? `${((currentValue < 0 ? adjustedTargetValue + Math.abs(currentValue) : adjustedTargetValue - currentValue) / 100).toFixed(2)}%`
                        : metricCalc.remainingValue.toFixed(0)}
                    </p>
                  )}
                </div>

                {/* Tagesdurchschnitt Soll */}
                <div>
                  <p className="text-sm font-medium">Tagesdurchschnitt Soll</p>
                  {dailyMetrics.isDeadlineReached ? (
                    <p className="text-sm opacity-70">Deadline erreicht</p>
                  ) : metricCalc.isCompleted ? (
                    <p className="text-sm opacity-70">Ziel erreicht</p>
                  ) : (
                    <p className="text-xl font-bold">
                      {metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START ? `${(dailyMetrics.requiredDaily / 100).toFixed(2)}%` : dailyMetrics.requiredDaily.toFixed(1)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TASK TABLE */}
        <div className="flex-1 min-h-0 mb-4">
          <TaskTable
            tasks={tasks}
            onToggleTask={handleToggleTask}
            getUserName={(userId: string) => userStore.getUserName(userId)}
            showAddButton={true}
            onEditTask={(taskId: string) => navigate(`/goals/task/edit/${goal.id}/${taskId}`)}
            onDeleteTask={handleDeleteTask}
            getGoalName={() => goal.description}
            goalId={goal.id}
            addTaskComponent={
              isAddingTask ? (
                <div className="flex items-center gap-2">
                  <input type="checkbox" checked={false} disabled className="checkbox checkbox-sm text-neutral-500" />
                  <input
                    ref={inputRef}
                    type="text"
                    value={newTaskTitle}
                    onChange={(e) => setNewTaskTitle(e.target.value)}
                    onKeyDown={handleAddTask}
                    onBlur={handleBlur}
                    placeholder="Neue Aufgabe eingeben... (Enter zum Speichern)"
                    className="w-full bg-transparent border-none focus:outline-none"
                  />
                </div>
              ) : undefined
            }
            onAddTask={() => setIsAddingTask(true)}
          />
        </div>
      </div>
    </div>
  );
});

export default GoalDetailsPage;
