import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { API_CONFIG } from "../config/api.config";
import { SelfTrackingEntryDTO } from "../backend-types";
import { RootStore } from "./RootStore";

// Use this interface for creating a new entry
export interface SelfTrackingEntryCreateData {
  entryDate: string;
  value: number;
  comment?: string;
  goalId: string;
}

// Use this interface for updating an existing entry
export interface SelfTrackingEntryUpdateData {
  entryDate: string;
  value: number;
  comment?: string;
}

export class SelfTrackingStore {
  entries: Map<string, SelfTrackingEntryDTO[]> = new Map(); // goalId -> entries
  isLoading: boolean = false;
  error: string | null = null;
  
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }
  
  // Get all entries for a goal
  async getEntriesForGoal(goalId: string) {
    this.isLoading = true;
    this.error = null;
    
    try {
      const response = await apiService.get<SelfTrackingEntryDTO[]>(`${API_CONFIG.ENDPOINTS.TRACKING_GET_ENTRIES}/${goalId}`);
      
      runInAction(() => {
        this.entries.set(goalId, response);
        this.isLoading = false;
      });
      
      return response;
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Fehler beim Laden der Tracking-Einträge';
        this.isLoading = false;
      });
      throw error;
    }
  }
  
  // Create a new entry
  async createEntry(data: SelfTrackingEntryCreateData) {
    this.isLoading = true;
    this.error = null;
    
    try {
      const response = await apiService.post<SelfTrackingEntryDTO>(API_CONFIG.ENDPOINTS.TRACKING_CREATE, data);
      
      runInAction(() => {
        const currentEntries = this.entries.get(data.goalId) || [];
        this.entries.set(data.goalId, [response, ...currentEntries]);
        this.isLoading = false;
      });
      
      return response;
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Fehler beim Erstellen des Tracking-Eintrags';
        this.isLoading = false;
      });
      throw error;
    }
  }
  
  // Update an existing entry
  async updateEntry(id: string, goalId: string, data: SelfTrackingEntryUpdateData) {
    this.isLoading = true;
    this.error = null;
    
    try {
      const response = await apiService.put<SelfTrackingEntryDTO>(`${API_CONFIG.ENDPOINTS.TRACKING_UPDATE}/${id}`, data);
      
      runInAction(() => {
        const currentEntries = this.entries.get(goalId) || [];
        const updatedEntries = currentEntries.map(entry => 
          entry.id === id ? response : entry
        );
        this.entries.set(goalId, updatedEntries);
        this.isLoading = false;
      });
      
      return response;
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Fehler beim Aktualisieren des Tracking-Eintrags';
        this.isLoading = false;
      });
      throw error;
    }
  }
  
  // Delete an entry
  async deleteEntry(id: string, goalId: string) {
    this.isLoading = true;
    this.error = null;
    
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.TRACKING_DELETE}/${id}`);
      
      runInAction(() => {
        const currentEntries = this.entries.get(goalId) || [];
        const updatedEntries = currentEntries.filter(entry => entry.id !== id);
        this.entries.set(goalId, updatedEntries);
        this.isLoading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message || 'Fehler beim Löschen des Tracking-Eintrags';
        this.isLoading = false;
      });
      throw error;
    }
  }
  
  // Get total value for a goal
  async getTotalValueForGoal(goalId: string) {
    try {
      return await apiService.get<number>(`${API_CONFIG.ENDPOINTS.TRACKING_TOTAL}/${goalId}`);
    } catch (error: any) {
      console.error('Fehler beim Laden des Gesamtwerts:', error);
      return 0;
    }
  }
  
  // Clear error
  clearError() {
    this.error = null;
  }
  
  // Reset store
  resetStore() {
    this.entries.clear();
    this.isLoading = false;
    this.error = null;
  }
} 