import React, { ReactNode } from "react";

interface BadgeProps {
  text: string;
  className?: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const StatusBadge: React.FC<BadgeProps> = ({ text, className = "", icon, onClick }) => {
  return (
    <div 
      className={`badge badge-md border-neutral rounded-sm gap-2 max-w-full px-3 py-3 ${className} ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      {icon}
      <span className="truncate" title={text}>
        {text}
      </span>
    </div>
  );
};

export default StatusBadge;
