import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { FilterDTO, FilterOperator, FilterRule, FilterType } from "../backend-types";
import { apiService } from "../ApiService";
import { API_CONFIG } from "../config/api.config";

// Value mappings for the enums
const FilterOperatorValues = {
  CONTAINS: "CONTAINS" as FilterOperator,
  EQUALS: "EQUALS" as FilterOperator,
  NOT_CONTAINS: "NOT_CONTAINS" as FilterOperator,
  NOT_EQUALS: "NOT_EQUALS" as FilterOperator,
};

const FilterTypeValues = {
  TARIFF_NAME: "TARIFF_NAME" as FilterType,
};

export class FilterStore {
  filters: FilterDTO[] = [];
  isLoading: boolean = false;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async refreshFilters() {
    this.isLoading = true;
    try {
      const response = await apiService.get<FilterDTO[]>(API_CONFIG.ENDPOINTS.FILTERS_ALL);
      runInAction(() => {
        this.filters = response;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Filter";
        this.isLoading = false;
      });
    }
  }

  async createFilter(filter: Omit<FilterDTO, "id">) {
    try {
      const response = await apiService.post<FilterDTO>(
        API_CONFIG.ENDPOINTS.FILTERS_CREATE, 
        filter
      );
      
      runInAction(() => {
        this.filters.push(response);
        this.error = null;
      });
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Erstellen des Filters";
      });
      throw error;
    }
  }

  async deleteFilter(id: string) {
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.FILTERS_DELETE}/${id}`);
      runInAction(() => {
        this.filters = this.filters.filter(filter => filter.id !== id);
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen des Filters";
      });
      throw error;
    }
  }

  async updateFilter(id: string, updates: Partial<FilterDTO>) {
    try {
      const response = await apiService.put<FilterDTO>(
        `${API_CONFIG.ENDPOINTS.FILTERS_UPDATE}/${id}`, 
        updates
      );

      runInAction(() => {
        const index = this.filters.findIndex(f => f.id === id);
        if (index !== -1) {
          this.filters[index] = response;
        }
        this.error = null;
      });
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Aktualisieren des Filters";
      });
      throw error;
    }
  }

  getOperatorLabel(operator: FilterOperator) {
    switch (operator) {
      case FilterOperatorValues.CONTAINS:
        return "beinhaltet";
      case FilterOperatorValues.EQUALS:
        return "entspricht";
      case FilterOperatorValues.NOT_CONTAINS:
        return "beinhaltet nicht";
      case FilterOperatorValues.NOT_EQUALS:
        return "entspricht nicht";
    }
  }

  getTypeLabel(type: FilterType) {
    switch (type) {
      case FilterTypeValues.TARIFF_NAME:
        return "Tarifname";
    }
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.filters = [];
    this.isLoading = false;
    this.error = null;
  }
}

// Export the value mappings for use in other components
export { FilterOperatorValues, FilterTypeValues }; 