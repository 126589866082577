import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FilterDTO, FilterRule } from "../backend-types";
import { useConfirmationModal } from "../components/ConfirmationModal";
import TableMenu, { MenuAction } from "../components/MenuAction";
import SidebarPageLayout from "../components/SidebarPageLayout";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { FilterOperatorValues, FilterTypeValues } from "../stores/FilterStore";
import { rootStore } from "../stores/RootStore";

const FilterPage: React.FC = observer(() => {
  const { filterStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();
  const [editingFilter, setEditingFilter] = useState<FilterDTO | null>(null);

  const [newFilter, setNewFilter] = useState<Omit<FilterDTO, "id">>({
    name: "",
    rules: [{
      type: FilterTypeValues.TARIFF_NAME,
      operator: FilterOperatorValues.CONTAINS,
      value: ""
    }]
  });

  useEffect(() => {
    filterStore.refreshFilters();
  }, [filterStore]);

  const handleAddRule = () => {
    if (editingFilter) {
      setEditingFilter(prev => ({
        ...prev!,
        rules: [...prev!.rules, {
          type: FilterTypeValues.TARIFF_NAME,
          operator: FilterOperatorValues.CONTAINS,
          value: ""
        }]
      }));
    } else {
      setNewFilter(prev => ({
        ...prev,
        rules: [...prev.rules, {
          type: FilterTypeValues.TARIFF_NAME,
          operator: FilterOperatorValues.CONTAINS,
          value: ""
        }]
      }));
    }
  };

  const handleRuleChange = (index: number, field: keyof FilterRule, value: string) => {
    if (editingFilter) {
      setEditingFilter(prev => ({
        ...prev!,
        rules: prev!.rules.map((rule, i) => 
          i === index ? { ...rule, [field]: value } : rule
        )
      }));
    } else {
      setNewFilter(prev => ({
        ...prev,
        rules: prev.rules.map((rule, i) => 
          i === index ? { ...rule, [field]: value } : rule
        )
      }));
    }
  };

  const handleRemoveRule = (index: number) => {
    if (editingFilter) {
      if (editingFilter.rules.length <= 1) return;
      
      setEditingFilter(prev => ({
        ...prev!,
        rules: prev!.rules.filter((_, i) => i !== index)
      }));
    } else {
      if (newFilter.rules.length <= 1) return;

      setNewFilter(prev => ({
        ...prev,
        rules: prev.rules.filter((_, i) => i !== index)
      }));
    }
  };

  const handleCreateFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newFilter.name || newFilter.rules.some(rule => !rule.value)) return;

    try {
      await filterStore.createFilter(newFilter);
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Filter wurde erfolgreich erstellt",
      });
      setNewFilter({
        name: "",
        rules: [{
          type: FilterTypeValues.TARIFF_NAME,
          operator: FilterOperatorValues.CONTAINS,
          value: ""
        }]
      });
    } catch (error) {
      const { type, message } = handleError(error, "Filter erstellen");
      toastStore.add({ type, caption: "Fehler", message });
    }
  };

  const handleDeleteFilter = async (id: string) => {
    const confirmed = await showConfirmationModal({
      title: "Filter löschen",
      text: "Möchten Sie diesen Filter wirklich löschen?",
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await filterStore.deleteFilter(id);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Filter wurde erfolgreich gelöscht",
        });
      } catch (error) {
        const { type, message } = handleError(error, "Filter löschen");
        toastStore.add({ type, caption: "Fehler", message });
      }
    }
  };

  const handleUpdateFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingFilter) return;

    const confirmed = await showConfirmationModal({
      title: "Filter aktualisieren",
      text: "Möchten Sie die Änderungen speichern?",
      confirmText: "Speichern",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await filterStore.updateFilter(editingFilter.id, editingFilter);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Filter wurde erfolgreich aktualisiert",
        });
        setEditingFilter(null);
      } catch (error) {
        const { type, message } = handleError(error, "Filter aktualisieren");
        toastStore.add({ type, caption: "Fehler", message });
      }
    }
  };

  const getMenuActions = (filter: FilterDTO): MenuAction[] => [
    {
      label: "Bearbeiten",
      icon: <PencilIcon className="size-4" />,
      onClick: () => setEditingFilter(filter),
    },
    {
      label: "Löschen",
      icon: <TrashIcon className="size-4" />,
      onClick: () => handleDeleteFilter(filter.id),
      variant: "error",
    },
  ];

  const renderFilterRow = (filter: FilterDTO) => (
    <tr key={filter.id} className="border-b border-neutral last:border-b-0">
      <td>
        <div className="flex-grow min-w-0 mr-2">
          <div className="text-sm font-bold truncate" title={filter.name}>
            {filter.name}
          </div>
          <div className="text-xs text-neutral-400">
            {filter.rules.slice(0, 3).map((rule, index) => (
              <div key={index} className="truncate">
                {filterStore.getTypeLabel(rule.type)} {filterStore.getOperatorLabel(rule.operator)} "{rule.value}"
              </div>
            ))}
            {filter.rules.length > 3 && (
              <div className="truncate text-neutral-400">
                {filter.rules.length - 3} weitere ...
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <TableMenu actions={getMenuActions(filter)} />
      </td>
    </tr>
  );

  return (
    <SidebarPageLayout 
      title="Filter"
      onRefresh={() => filterStore.refreshFilters()}
    >
      <ConfirmationModalComponent />
      <div className="flex gap-16">
        {/* Linke Seite - Filterliste */}
        <div className="flex-1 border border-neutral rounded p-4">
          <div className="text-lg font-bold mb-4">Vorhandene Filter</div>
          <div className="max-h-[60vh] overflow-y-auto">
            <table className="table w-full">
              <tbody>
                {filterStore.filters.map(renderFilterRow)}
              </tbody>
            </table>
          </div>
        </div>

        {/* Rechte Seite - Filter erstellen/bearbeiten */}
        <div className="flex-1 border border-neutral rounded p-4">
          <div className="text-lg font-bold mb-4">
            {editingFilter ? "Filter bearbeiten" : "Filter erstellen"}
          </div>
          <form onSubmit={editingFilter ? handleUpdateFilter : handleCreateFilter}>
            <div className="flex flex-col gap-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered"
                  value={editingFilter?.name ?? newFilter.name}
                  onChange={(e) => {
                    if (editingFilter) {
                      setEditingFilter({ ...editingFilter, name: e.target.value });
                    } else {
                      setNewFilter({ ...newFilter, name: e.target.value });
                    }
                  }}
                  placeholder="Filtername"
                />
              </div>

              <div className="form-control">
                <div className="max-h-[40vh] overflow-y-auto">
                  {(editingFilter?.rules ?? newFilter.rules).map((rule, index) => (
                    <div key={index} className="flex gap-2 items-center mb-2">
                      <span className="text-sm">Regel:</span>
                      <select 
                        className="select select-bordered select-sm flex-1"
                        value={rule.type}
                        onChange={(e) => handleRuleChange(index, 'type', e.target.value)}
                      >
                        <option value={FilterTypeValues.TARIFF_NAME}>Tarifname</option>
                      </select>
                      <select 
                        className="select select-bordered select-sm flex-1"
                        value={rule.operator}
                        onChange={(e) => handleRuleChange(index, 'operator', e.target.value)}
                      >
                        <option value={FilterOperatorValues.CONTAINS}>beinhaltet</option>
                        <option value={FilterOperatorValues.EQUALS}>entspricht</option>
                        <option value={FilterOperatorValues.NOT_CONTAINS}>beinhaltet nicht</option>
                        <option value={FilterOperatorValues.NOT_EQUALS}>entspricht nicht</option>
                      </select>
                      <input
                        type="text"
                        className="input input-bordered input-sm flex-1"
                        value={rule.value}
                        onChange={(e) => handleRuleChange(index, 'value', e.target.value)}
                        placeholder="Wert"
                      />
                      {((editingFilter?.rules?.length ?? 0) > 1 || (!editingFilter && newFilter.rules.length > 1)) && (
                        <button
                          type="button"
                          className="btn btn-ghost btn-sm px-2"
                          onClick={() => handleRemoveRule(index)}
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex gap-2 mt-4">
                <button
                  type="button"
                  className="btn btn-ghost"
                  onClick={handleAddRule}
                >
                  + Regel hinzufügen
                </button>
                {editingFilter && (
                  <button
                    type="button"
                    className="btn btn-ghost"
                    onClick={() => setEditingFilter(null)}
                  >
                    Abbrechen
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    editingFilter 
                      ? !editingFilter.name || editingFilter.rules.some(rule => !rule.value)
                      : !newFilter.name || newFilter.rules.some(rule => !rule.value)
                  }
                >
                  {editingFilter ? "Filter aktualisieren" : "Filter speichern"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SidebarPageLayout>
  );
});

export default FilterPage; 