import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilterDTO } from "../backend-types";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";
import { rootStore } from "../stores/RootStore";

const EditGoalPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { goalStore, userStore, customerStore, groupStore, filterStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  const [goal, setGoal] = useState<any>(null);
  const [originalGoal, setOriginalGoal] = useState<any>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        await groupStore.refreshGroups();
        await userStore.refreshUsers();
        await filterStore.refreshFilters();
        const goalData = await goalStore.getGoal(id!);
        setGoal(goalData);
        setOriginalGoal(goalData);
      } catch (error) {
        const { type, message } = handleError(error, "Ziel laden");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to load goal:", error);
        navigate("/goals");
      }
    };
    loadData();
  }, [id, goalStore, userStore, groupStore, filterStore, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setGoal((prev: any) => ({
      ...prev,
      [name]: name === "targetValue" ? parseInt(value) || 0 : name === "endDate" ? new Date(value) : value,
    }));
  };

  const handleOwnerSelect = (userId: string) => {
    setGoal((prev: any) => ({
      ...prev,
      ownerId: userId,
    }));
  };

  const handleFilterSelect = (filter: FilterDTO) => {
    setGoal((prev: any) => ({
      ...prev,
      filters: [...(prev.filters || []), filter.id],
    }));
  };

  const handleFilterRemove = (filterId: string) => {
    setGoal((prev: any) => ({
      ...prev,
      filters: (prev.filters || []).filter((id: string) => id !== filterId),
    }));
  };

  const hasUnsavedChanges = useCallback(() => {
    if (!goal || !originalGoal) return false;
    
    // Check if filters have changed
    const originalFilters = originalGoal.filters || [];
    const currentFilters = goal.filters || [];
    const filtersChanged = 
      originalFilters.length !== currentFilters.length || 
      originalFilters.some((id: string) => !currentFilters.includes(id));
    
    return goal.description !== originalGoal.description || 
           goal.ownerId !== originalGoal.ownerId || 
           goal.endDate !== originalGoal.endDate || 
           goal.targetValue !== originalGoal.targetValue ||
           filtersChanged;
  }, [goal, originalGoal]);

  const handleNavigation = useCallback(
    async (path: string) => {
      if (hasUnsavedChanges()) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [hasUnsavedChanges, navigate, showConfirmationModal]
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await goalStore.updateGoal(id!, {
        description: goal.description,
        ownerId: goal.ownerId,
        startDate: goal.startDate,
        endDate: goal.endDate,
        targetValue: goal.targetValue,
        filters: goal.filters || [],
      });
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Ziel wurde erfolgreich aktualisiert",
      });
      navigate("/goals");
    } catch (error) {
      const { type, message } = handleError(error, "Ziel aktualisieren");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to update goal:", error);
    }
  };

  if (!goal) return null;
  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Ziel bearbeiten</span>
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Metric Type - Read Only */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Kennzahl</span>
                  </td>
                  <td>
                    <div className="text-neutral-500">{goalStore.getMetricTypeLabel(goal.metricType)}</div>
                  </td>
                </tr>

                {/* Target Value */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Zielwert</span>
                  </td>
                  <td>
                    <div>
                      <input type="number" name="targetValue" value={goal.targetValue} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required min="0" />
                    </div>
                  </td>
                </tr>

                {/* Start Date - Read Only */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Startdatum</span>
                  </td>
                  <td>
                    <div className="text-neutral-500">{DateTime.fromJSDate(new Date(goal.startDate)).toFormat("dd.MM.yyyy")}</div>
                  </td>
                </tr>

                {/* End Date */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Enddatum</span>
                  </td>
                  <td>
                    <div>
                      <input
                        type="date"
                        name="endDate"
                        value={DateTime.fromJSDate(new Date(goal.endDate)).toFormat("yyyy-MM-dd")}
                        onChange={handleChange}
                        className="input input-sm input-bordered w-full rounded"
                        required
                      />
                    </div>
                  </td>
                </tr>

                {/* Studio/Group - Read Only */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Zuordnung</span>
                  </td>
                  <td>
                    {!goal.groupId && !goal.studioId ? (
                      // No assignment at all
                      <div className="text-red-400">Zuordnung fehlt/gelöscht</div>
                    ) : goal.groupId ? (
                      // Group assignment
                      <div className={`text-neutral-500`}>{groupStore.groups.find((g) => g.id === goal.groupId)?.groupName || "UNBEKANNTE GRUPPE"}</div>
                    ) : (
                      // Studio assignment
                      <div className={`text-neutral-500`}>{customerStore.getStudio(goal.studioId)?.name || "UNBEKANNTES STUDIO"}</div>
                    )}
                  </td>
                </tr>

                {/* Filters Selection */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Filter</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-wrap gap-2">
                        {(goal.filters || []).map((filterId: string) => {
                          const filter = filterStore.filters.find(f => f.id === filterId);
                          return filter ? (
                            <div key={filter.id} className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                              {filter.name}
                              <button
                                type="button"
                                onClick={() => handleFilterRemove(filter.id)}
                                className="btn btn-ghost btn-xs p-0"
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </button>
                            </div>
                          ) : null;
                        })}
                      </div>
                      <div className="dropdown w-full bg-base-100 border border-neutral rounded">
                        <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                          Filter hinzufügen
                          <ChevronDownIcon className="h-4 w-4" />
                        </div>
                        <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                          <div className="flex flex-col gap-1">
                            {filterStore.filters
                              .filter(filter => !(goal.filters || []).includes(filter.id))
                              .map(filter => (
                                <div
                                  key={filter.id}
                                  className="cursor-pointer hover:bg-neutral rounded-lg p-2"
                                  onClick={() => handleFilterSelect(filter)}
                                >
                                  {filter.name}
                                </div>
                              ))}
                            {filterStore.filters.length === 0 && (
                              <div className="p-2 text-neutral-500">Keine Filter verfügbar</div>
                            )}
                            {filterStore.filters.length > 0 && filterStore.filters.filter(filter => !(goal.filters || []).includes(filter.id)).length === 0 && (
                              <div className="p-2 text-neutral-500">Alle Filter bereits ausgewählt</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                {/* Description - Editable */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Beschreibung</span>
                  </td>
                  <td>
                    <div>
                      <textarea name="description" value={goal.description} onChange={handleChange} className="textarea textarea-bordered w-full rounded" rows={3} />
                    </div>
                  </td>
                </tr>

                {/* Owner - Editable */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Verantwortlich</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      {goal.ownerId ? (
                        <div className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                          {userStore.getUserName(goal.ownerId)}
                          <button type="button" onClick={() => setGoal((prev: any) => ({ ...prev, ownerId: "" }))} className="btn btn-ghost btn-xs p-0">
                            <XMarkIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="dropdown w-full bg-base-100 border border-neutral rounded">
                          <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                            Person wählen
                            <ChevronDownIcon className="h-4 w-4" />
                          </div>
                          <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                            <div className="flex flex-col gap-1">
                              {userStore.users.map((user) => (
                                <div key={user.id} className="cursor-pointer hover:bg-neutral rounded-lg p-2" onClick={() => handleOwnerSelect(user.id)}>
                                  {user.firstname} {user.lastname}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button type="button" className="btn btn-neutral" onClick={() => handleNavigation("/goals")}>
                        Abbrechen
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={!hasUnsavedChanges()}>
                        Änderungen speichern
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});

export default EditGoalPage;
