import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

interface SearchBarProps {
  onSearch: (term: string) => void;
  placeholder?: string;
  paramName?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, placeholder = "Suchen", paramName = "q" }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(() => {
    const initialTerm = searchParams.get(paramName) || "";
    if (initialTerm) {
      onSearch(initialTerm);
    }
    return initialTerm;
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = e.target.value;
    setSearchTerm(newTerm);
    onSearch(newTerm);

    if (newTerm) {
      searchParams.set(paramName, newTerm);
    } else {
      searchParams.delete(paramName);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="relative">
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-neutral-500" />
      <input 
        type="text" 
        className="input input-bordered w-full pl-10 text-sm placeholder:text-neutral-500" 
        placeholder={placeholder} 
        value={searchTerm} 
        onChange={handleChange} 
      />
    </div>
  );
};

export default SearchBar;
