import React from "react";
import { MetricType } from "../stores/GoalStore";

interface ProgressBarProps {
  current: number;
  goal: number;
  timeProgress: number;
  goalProgress: number;
  metricType?: MetricType;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ current, goal, timeProgress, goalProgress, metricType }) => {
  const percentage = (current / goal) * 100;
  const barWidth = Math.min(percentage, 100);

  const getProgressColor = (goalProgress: number, timeProgress: number) => {
    if (goalProgress >= 100) {
      return "bg-green-500";
    }
    const progressDifference = goalProgress - timeProgress;
    if (progressDifference < -5) {
      return "bg-red-500";
    } else if (progressDifference > 5) {
      return "bg-green-500";
    } else {
      return "bg-yellow-500";
    }
  };

  const colorClass = getProgressColor(goalProgress, timeProgress);

  const isPercentageMetric = metricType === MetricType.NET_GROWTH_PERCENTAGE_FROM_START;

  return (
    <div className="w-full">
      <div className="flex justify-between text-xs mb-1">
        <span>{percentage.toFixed(1)}%</span>
        <span>{isPercentageMetric ? `${current.toFixed(2)}% / ${goal.toFixed(0)}%` : `${current.toFixed(0)} / ${goal.toFixed(0)}`}</span>
      </div>
      <div className="relative w-full h-3 rounded bg-[#444444]">
        <div className={`h-full ${colorClass} rounded transition-all duration-500 ease-in-out`} style={{ width: `${barWidth}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
