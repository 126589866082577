import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { DateTime } from "luxon";
import { GoalCreateDTO, GoalDTO, GoalUpdateDTO, StudioCancellationMetricsProjection, StudioMetricsProjection } from "../backend-types";
import { RootStore } from "./RootStore";
import { API_CONFIG } from "../config/api.config";

export interface MetricsState {
  isLoading: boolean;
  error: string | null;
  value: number | null;
  rawData?: StudioMetricsProjection[] | StudioCancellationMetricsProjection[];
}

interface MetricCalculation {
  progress: number;
  currentValue: number;
  isCompleted: boolean;
  remainingValue: number;
}

interface DailyMetrics {
  currentDaily: number;
  requiredDaily: number;
  projectedValue: number;
  timeProgress: number;
  isDeadlineReached: boolean;
}

export enum MetricType {
  NEW_MEMBERS = "NEW_MEMBERS",
  NEW_MEMBERS_ACTIVE = "NEW_MEMBERS_ACTIVE",
  CANCELLATIONS = "CANCELLATIONS",
  INCOMING_CANCELLATIONS = "INCOMING_CANCELLATIONS",
  NET_GROWTH = "NET_GROWTH",
  NET_GROWTH_FORECAST = "NET_GROWTH_FORECAST",
  NET_GROWTH_PERCENTAGE_FROM_START = "NET_GROWTH_PERCENTAGE_FROM_START",
  NET_GROWTH_ABSOLUTE_FROM_START = "NET_GROWTH_ABSOLUTE_FROM_START",
  SELF_TRACKING = "SELF_TRACKING",
}

export class GoalStore {
  goals: GoalDTO[] = [];
  isLoading: boolean = false;
  error: string | null = null;
  metricsState: Map<string, MetricsState> = new Map();

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  private initializeMetricsState(goalId: string) {
    if (!this.metricsState.has(goalId)) {
      this.metricsState.set(goalId, {
        isLoading: false,
        error: null,
        value: null,
      });
    }
  }

  getMetricTypeLabel(type: MetricType): string {
    switch (type) {
      case MetricType.NEW_MEMBERS:
        return "Neuabschlüsse";
      case MetricType.NEW_MEMBERS_ACTIVE:
        return "Neumitglieder";
      case MetricType.CANCELLATIONS:
        return "Abgänge";
      case MetricType.INCOMING_CANCELLATIONS:
        return "Eingehende Kündigungen";
      case MetricType.NET_GROWTH:
        return "Nettowachstum";
      case MetricType.NET_GROWTH_FORECAST:
        return "Nettowachstum (Forecast)";
      case MetricType.NET_GROWTH_PERCENTAGE_FROM_START:
        return "Nettowachstum (Absolut, %)";
      case MetricType.NET_GROWTH_ABSOLUTE_FROM_START:
        return "Nettowachstum (Tagesdurchschnitt)";
      case MetricType.SELF_TRACKING:
        return "Self-Tracking";
    }
  }

  calculateMetricValue(data: (StudioMetricsProjection | StudioCancellationMetricsProjection)[], metricType: String): number {
    if (!data || data.length === 0) return 0;

    const lastNonZeroEntry = [...data].reverse().find((d) => d.runningTotal > 0);

    if (!lastNonZeroEntry) return 0;

    if (metricType === MetricType.CANCELLATIONS || metricType === MetricType.INCOMING_CANCELLATIONS) {
      return (lastNonZeroEntry as StudioCancellationMetricsProjection).runningTotal;
    }
    return (lastNonZeroEntry as StudioMetricsProjection).runningTotal;
  }

  calculateMetricProgress(metricType: MetricType, currentValue: number | null | undefined, targetValue: number): MetricCalculation {
    const current = currentValue || 0;

    switch (metricType) {
      // case MetricType.CANCELLATIONS:
      //   return {
      //     progress: current <= targetValue ? 100 : Math.max(0, (targetValue / current) * 100),
      //     currentValue: Math.max(0, targetValue - current),
      //     isCompleted: current <= targetValue,
      //     remainingValue: Math.max(0, current - targetValue),
      //   };
      default:
        return {
          progress: Math.min(100, (current / targetValue) * 100),
          currentValue: current,
          isCompleted: current >= targetValue,
          remainingValue: Math.max(0, targetValue - current),
        };
    }
  }

  getMetricDisplayValue(metricType: MetricType, rawValue: number, targetValue: number): number {
    // if (metricType === MetricType.CANCELLATIONS) {
    //   return Math.max(0, targetValue - rawValue);
    // }
    return rawValue;
  }

  calculateDailyMetrics(
    metricType: MetricType,
    metrics: (StudioMetricsProjection | StudioCancellationMetricsProjection)[],
    currentValue: number,
    targetValue: number,
    elapsedDays: number,
    totalDays: number
  ): DailyMetrics {
    const isDeadlineReached = elapsedDays >= totalDays;
    const metricCalc = this.calculateMetricProgress(metricType, currentValue, targetValue);

    let currentDaily = 0;
    if (metrics.length > 0) {
      if (metricType === MetricType.CANCELLATIONS || metricType === MetricType.INCOMING_CANCELLATIONS) {
        currentDaily = (metrics as StudioCancellationMetricsProjection[]).reduce((sum: number, metric) => sum + metric.dailyCount, 0) / metrics.length;
      } else {
        currentDaily = (metrics as StudioMetricsProjection[]).reduce((sum: number, metric) => sum + metric.averageDailyCount, 0) / metrics.length;
      }
    }

    const requiredDaily = isDeadlineReached || metricCalc.isCompleted ? 0 : Number((metricCalc.remainingValue / Math.max(totalDays - elapsedDays, 1)).toFixed(2));

    let projectedValue = currentValue;
    if (!isDeadlineReached) {
      const validDaily = isFinite(currentDaily) ? currentDaily : metrics.filter((m) => m.dailyCount > 0).reduce((sum: number, m) => sum + m.dailyCount, 0) / elapsedDays;

      projectedValue = currentValue + validDaily * (totalDays - elapsedDays);
      projectedValue = isNaN(projectedValue) ? currentValue : projectedValue;
    }

    const timeProgress = (elapsedDays / totalDays) * 100;

    return {
      currentDaily,
      requiredDaily,
      projectedValue,
      timeProgress,
      isDeadlineReached,
    };
  }

  async loadMetricsForGoal(goalId: string, metricType: String) {
    this.initializeMetricsState(goalId);
    const goal = this.goals.find((g) => g.id === goalId);
    if (!goal) return;

    runInAction(() => {
      this.metricsState.set(goalId, {
        ...this.metricsState.get(goalId)!,
        isLoading: true,
        error: null,
      });
    });

    try {
      let endpoint;
      
      // For SELF_TRACKING, we need to load data from the tracking API
      if (metricType === MetricType.SELF_TRACKING) {
        endpoint = API_CONFIG.ENDPOINTS.TRACKING_TOTAL;
        const url = `${endpoint}/${goalId}`;
        const totalValue = await apiService.get<number>(url);
        
        // For self-tracking, we manually create a projection that can be consumed by the UI
        const today = new Date();
        const projection = [
          {
            date: goal.startDate,
            dailyCount: 0,
            runningTotal: 0,
            averageDailyCount: 0,
            latestCount: 0,
            dailyRevenue: 0
          },
          {
            date: today,
            dailyCount: totalValue,
            runningTotal: totalValue,
            averageDailyCount: totalValue / Math.max(1, (today.getTime() - new Date(goal.startDate).getTime()) / (1000 * 60 * 60 * 24)),
            latestCount: totalValue,
            dailyRevenue: 0
          }
        ] as StudioMetricsProjection[];
        
        runInAction(() => {
          this.metricsState.set(goalId, {
            isLoading: false,
            error: null,
            value: totalValue,
            rawData: projection,
          });
        });
        return;
      }
      
      // Regular metrics endpoints
      switch (metricType) {
        case MetricType.NEW_MEMBERS:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NEW_MEMBERS;
          break;
        case MetricType.NEW_MEMBERS_ACTIVE:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NEW_MEMBERS_ACTIVE;
          break;
        case MetricType.NET_GROWTH_FORECAST:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NET_GROWTH_FORECAST;
          break;
        case MetricType.NET_GROWTH:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NET_GROWTH;
          break;
        case MetricType.CANCELLATIONS:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_CANCELLATIONS;
          break;
        case MetricType.INCOMING_CANCELLATIONS:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_INCOMING_CANCELLATIONS;
          break;
        case MetricType.NET_GROWTH_PERCENTAGE_FROM_START:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NET_GROWTH_PERCENTAGE_FROM_START;
          break;
        case MetricType.NET_GROWTH_ABSOLUTE_FROM_START:
          endpoint = API_CONFIG.ENDPOINTS.METRICS_NET_GROWTH_ABSOLUTE_FROM_START;
          break;
      }

      let queryParams = `startDate=${goal.startDate}&endDate=${goal.endDate}&goalId=${goalId}`;
      if (goal.studioId && goal.studioId !== -1) {
        queryParams += `&studioId=${goal.studioId}`;
      } else if (goal.groupId) {
        queryParams += `&studioGroupId=${goal.groupId}`;
      }

      const url = `${endpoint}?${queryParams}`;
      const response = await apiService.get<StudioMetricsProjection[] | StudioCancellationMetricsProjection[]>(url);
      const metricValue = this.calculateMetricValue(response, metricType);

      runInAction(() => {
        this.metricsState.set(goalId, {
          isLoading: false,
          error: null,
          value: metricValue,
          rawData: response,
        });
      });
    } catch (error) {
      console.error("Request failed:", error);
      runInAction(() => {
        this.metricsState.set(goalId, {
          ...this.metricsState.get(goalId)!,
          isLoading: false,
          error: "ERROR",
          value: null,
        });
      });
    }
  }

  async createGoal(dto: GoalCreateDTO) {
    try {
      const response = await apiService.post<GoalDTO>(API_CONFIG.ENDPOINTS.GOALS_CREATE, dto);
      runInAction(() => {
        this.goals.push(response);
        this.error = null;
      });

      await this.loadMetricsForGoal(response.id, response.metricType as MetricType);
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Erstellen des Ziels";
      });
      throw error;
    }
  }

  async deleteGoal(id: string) {
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.GOALS_DELETE}/${id}`);
      runInAction(() => {
        this.goals = this.goals.filter((goal) => goal.id !== id);
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen des Ziels";
      });
      throw error;
    }
  }

  async getGoal(id: string) {
    try {
      const response = await apiService.get<GoalDTO>(`${API_CONFIG.ENDPOINTS.GOALS_GET}/${id}`);
      if (!response) {
        throw new Error("Goal not found");
      }

      runInAction(() => {
        response.startDate = new Date(response.startDate);
        response.endDate = new Date(response.endDate);
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden des Ziels";
      });
      throw error;
    }
  }

  async updateGoal(id: string, updateData: GoalUpdateDTO) {
    try {
      const response = await apiService.put<GoalDTO>(`${API_CONFIG.ENDPOINTS.GOALS_UPDATE}/${id}`, updateData);
      runInAction(() => {
        const index = this.goals.findIndex((g) => g.id === id);
        if (index !== -1) {
          this.goals[index] = response;
        }
        this.error = null;
      });
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Aktualisieren des Ziels";
      });
      throw error;
    }
  }

  getMetricsState(goalId: string): MetricsState {
    this.initializeMetricsState(goalId);
    return this.metricsState.get(goalId)!;
  }

  async refreshGoals() {
    this.isLoading = true;
    try {
      const response = await apiService.get<GoalDTO[]>(API_CONFIG.ENDPOINTS.GOALS_ALL);
      runInAction(() => {
        this.goals = response;
        this.isLoading = false;
        this.error = null;

        response.forEach((goal) => {
          this.loadMetricsForGoal(goal.id, goal.metricType as MetricType);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Ziele";
        this.isLoading = false;
      });
      throw error;
    }
  }

  get plannedGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.startDate.toString()) > now);
  }

  get activeGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.startDate.toString()) <= now && DateTime.fromISO(goal.endDate.toString()) >= now);
  }

  get completedGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.endDate.toString()) < now);
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.goals = [];
    this.isLoading = false;
    this.error = null;
    this.metricsState.clear();
  }
}
